import logo from "./logo.svg";
import "./App.css";
import MapForms from "./mapForm";
import Map from "./map";
import ContactFrom from "./contactForms";
import GivingThanksForm from "./givingThanksForm";
import GivingThanksFormConfirmation from "./givingThanksFormConfirmation";
import Homepage from "./homePage";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import VideoPlayer from "./videoPlayer";
import ExportCSV from "./exportFile";
import RepPortal from "./portal";
import IframeComponent from "./iframe";
import ThankYou from "./thankYou";
import FinalReport from "./finalReport";
import GluChallenge from "./gluChallenge";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="contact-form" element={<ContactFrom />} />
        <Route path="giving-thanks-form" element={<GivingThanksForm />} />
        <Route path="giving-thanks-form-confirmation" element={<GivingThanksFormConfirmation />} />
        <Route path="map" element={<Map />} />
        <Route path="map-form" element={<MapForms />} />
        <Route path="play-video" element={<VideoPlayer />} />
        <Route path="export-excel" element={<ExportCSV />} />
        <Route path="tools" element={<IframeComponent />} />
        <Route path="rep-portal" element={<RepPortal />} />
        <Route path="thanks" element={<ThankYou />} />
        <Route path="final-report" element={<FinalReport />} />
        <Route path="glu-challenge" element={<GluChallenge />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
