import React from "react";
import stateLabelValues from "./stateValues";
import refinedCounties from "./countyValues";
import { useState } from "react";
import "./CSS/mapForm.css";
// import Checkbox from "./checkBox";
import RadioButton from "./radioButton";
// import { useEffect } from "react";
// import { useNavigate } from "react-router-dom";

const MapForms = (props) => {
  const hero = require("../src/map_top_pic.jpg");
  // const navigate = useNavigate();

  const [county, setCounty] = useState([]);
  // const [resistantCheckBoxDict, setResistantCheckBox] = useState({});
  // const [resitantCheckBoxValidation, setResistantCheckBoxValidation] =
  //   useState(true);

  const [sdsQuestionRadio, setsdsQuestionRadio] = useState("");
  const [scnQuestionRadio, setscnQuestionRadio] = useState("");

  const [waterHempRadio, setWaterHempRadio] = useState("");
  const [tarspotRadio, setTarspotRadio] = useState("");

  const [tabState, setTabState] = useState(1);

  const handleSubmit = (e) => {
    // //check if atleast oneCheckBox is for plant
    // let isFieldCheckBoxValid = returnCheckBoxValidationCriteria(
    //   resistantCheckBoxDict
    // );
    // setResistantCheckBoxValidation(isFieldCheckBoxValid);

    // if (!isFieldCheckBoxValid && waterHempRadio == "YES") {
    //   e.preventDefault();
    //   return false;
    // }
    return true;
  };

  // const checkResistantCheckBox = (e) => {
  //   let tempDict = resistantCheckBoxDict;
  //   tempDict[e.target.id] = e.target.checked;

  //   setResistantCheckBox(tempDict);

  //   let containsAtLeastOneChecked = returnCheckBoxValidationCriteria(
  //     resistantCheckBoxDict
  //   );
  //   setResistantCheckBoxValidation(containsAtLeastOneChecked);
  // };

  // const returnCheckBoxValidationCriteria = (checkBoxDict) => {
  //   for (let key in checkBoxDict) {
  //     if (checkBoxDict[key] === true) {
  //       return true;
  //     }
  //   }
  //   return false;
  // };

  const handleStateChange = (e) => {
    const value = e.target.value;
    if (value.length > 0) {
      let listOfCounties = refinedCounties[value];
      setCounty(listOfCounties);
    } else {
      let temp = [];
      setCounty(temp);
    }
  };

  const onChangeSCNQuestion = (e) => {
    setscnQuestionRadio(e.target.getAttribute("hiddenvalue"));
  };

  const onChangeSDSQuestion = (e) => {
    setsdsQuestionRadio(e.target.getAttribute("hiddenvalue"));
  };

  const onChangeTarSpotRadio = (e) => {
    setTarspotRadio(e.target.getAttribute("hiddenvalue"));
  };

  const onChangeWaterHempRadio = (e) => {
    setWaterHempRadio(e.target.getAttribute("hiddenvalue"));

    // if (e.target.getAttribute("hiddenvalue") == "NO") {
    //   setResistantCheckBox({});
    // }
  };

  return (
    <div className="main-form">
      <div className="hero">
        <img src={hero} alt="" />
      </div>
      <div className="content">

        <form autoComplete="off" name="map" method="post">
          <div className="tabs">
            <div className={"tab " + (tabState === 1 ? "selected" : "")} onClick={() => setTabState(1)}>All</div>
            <div className={"tab " + (tabState === 2 ? "selected" : "")} onClick={() => setTabState(2)}>Fungicides</div>
            <div className={"tab " + (tabState === 3 ? "selected" : "")} onClick={() => setTabState(3)}>Herbicides</div>
            <div className={"tab " + (tabState === 4 ? "selected" : "")} onClick={() => setTabState(4)}>Seed and Seed Treatment</div>
          </div>

          <h2>Live Mapping Yield Threats</h2>

          <input type="hidden" name="form-name" value="map2024" />

          <div className="question">
            <div className="select-field">
              <label>State</label>
              <div className="select-wrapper">
                <select
                  id="state-dropdown"
                  name="state"
                  onChange={handleStateChange}
                  required
                >
                  <option value="">Please select a state</option>
                  {stateLabelValues.map((state, key) => {
                    return (
                      <option key={key} value={state.value}>
                        {state.label}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          </div>

          <div className="question">
            <div className="select-field">
              <label>County 1</label>
              <div className="select-wrapper">
                <select id="county-drop-down" name="county" required>
                  <option value="">Please select your County</option>
                  {county.length > 0
                    ? county.map((counties, key) => {
                      return (
                        <option key={key} value={counties}>
                          {counties}
                        </option>
                      );
                    })
                    : ""}
                </select>
              </div>
            </div>

            <div className="select-field">
              <label>County 2</label>
              <div className="select-wrapper">
                <select id="county-drop-down2" name="county2">
                  <option value="">Please select your County</option>
                  {county.length > 0
                    ? county.map((counties, key) => {
                      return (
                        <option key={key} value={counties}>
                          {counties}
                        </option>
                      );
                    })
                    : ""}
                </select>
              </div>
            </div>

            <div className="select-field">
              <label>County 3</label>
              <div className="select-wrapper">
                <select id="county-drop-down3" name="county3">
                  <option value="">Please select your County</option>
                  {county.length > 0
                    ? county.map((counties, key) => {
                      return (
                        <option key={key} value={counties}>
                          {counties}
                        </option>
                      );
                    })
                    : ""}
                </select>
              </div>
            </div>
          </div>

          {(tabState === 1 || tabState === 2) &&
            <div className="question" onChange={onChangeTarSpotRadio}>
              <label>
                Did you experience Tar Spot pressure in your corn fields in 2023?
              </label>
              <RadioButton
                id="tar-spot-yes"
                name="tar-spot"
                value="Yes"
                hiddenvalue="YES"
              ></RadioButton>
              <RadioButton
                id="tar-spot-no"
                name="tar-spot"
                value="No"
                hiddenvalue="NO"
              ></RadioButton>
            </div>
          }

          {(tabState === 1 || tabState === 3) &&
            <>
              <div className="question" onChange={onChangeWaterHempRadio}>
                <label>
                  Did you see resistant waterhemp in your fields in 2023?
                </label>
                <RadioButton
                  id="water-hemp-yes"
                  name="water-hemp"
                  value="Yes"
                  hiddenvalue="YES"
                ></RadioButton>
                <RadioButton
                  id="water-hemp-no"
                  name="water-hemp"
                  value="No"
                  hiddenvalue="NO"
                ></RadioButton>
              </div>


              {/* {waterHempRadio == "YES" ? (
                <div className="question resistantQuestion">
                  <label>
                    If yes, was it resistant to the following? (check all that
                    apply)
                  </label>

                  <span
                    className={
                      resitantCheckBoxValidation
                        ? "no-validation-error"
                        : "validation-error"
                    }
                  >
                    *Please select at least one option
                  </span>
                  <div className="checkbox-group">
                    <Checkbox
                      id="HPPD"
                      name="HPPD"
                      onChange={checkResistantCheckBox}
                      label="HPPD"
                    ></Checkbox>
                    <Checkbox
                      id="Glyphosate"
                      name="Glyphosate"
                      onChange={checkResistantCheckBox}
                      label="Glyphosate"
                    ></Checkbox>
                    <Checkbox
                      id="Other"
                      name="Other"
                      onChange={checkResistantCheckBox}
                      label="Other"
                    ></Checkbox>
                    <Checkbox
                      id="Multiple"
                      name="Multiple"
                      onChange={checkResistantCheckBox}
                      label="Multiple"
                    ></Checkbox>
                    <Checkbox
                      id="Unknown"
                      name="Unknown"
                      onChange={checkResistantCheckBox}
                      label="Unknown"
                    ></Checkbox>
                  </div>
                </div>
              ) : (
                ""
              )} */}
            </>
          }

          {(tabState === 1 || tabState === 4) &&
            <>
              <div className="question" onChange={onChangeSDSQuestion}>
                <label>
                  Did you see Sudden Death Syndrome (SDS) in your soybean fields in
                  2023?
                </label>
                <RadioButton
                  hiddenvalue="YES"
                  id="sds-yes"
                  name="SDS"
                  value="Yes"
                ></RadioButton>
                <RadioButton
                  hiddenvalue="NO"
                  id="sds-no"
                  name="SDS"
                  value="No"
                ></RadioButton>
              </div>

              <div className="question" onChange={onChangeSCNQuestion}>
                <label>
                  Did you see Soybean Cyst Nematode (SCN) in your soybean fields in
                  2023?
                </label>
                <RadioButton
                  hiddenvalue="YES"
                  name="SCN"
                  value={"Yes"}
                ></RadioButton>
                <RadioButton hiddenvalue="NO" name="SCN" value={"No"}></RadioButton>
              </div>
            </>
          }

          <div hidden>
            <input
              id="hidden-tarspot"
              type="text"
              name="tar-spot-hidden"
              value={tarspotRadio}
              hidden
            />

            <input
              id="hidden-waterhemp"
              type="text"
              name="water-hemp-hidden"
              value={waterHempRadio}
              hidden
            />

            <input
              id="hidden-sds"
              type="text"
              name="sds-hidden"
              hidden
              value={sdsQuestionRadio}
            />

            <input
              id="hidden-scn"
              type="text"
              name="scn-hidden"
              hidden
              value={scnQuestionRadio}
            />
          </div>

          <input type="submit" value="SUBMIT" onClick={handleSubmit} />
        </form>
      </div>
    </div>
  );
};

export default MapForms;
