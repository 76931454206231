import React from "react";
import { useEffect } from "react";
import { Navigate } from "react-router-dom";
import "./CSS/givingThanksFormConfirmation.css";
import { useNavigate } from "react-router-dom";


const GivingThanksFormConfirmation = () => {
  const hero = require("../src/contact-form-hero.jpg");
  const navigate = useNavigate()
  const delay = 15


  useEffect(
    () => {
      let timer = setTimeout(() => navigate("/giving-thanks-form"), 15 * 1000);
      return () => {
        clearTimeout(timer);
      };
    },

    []
  );

  return (
    <div className="thank-you">
      <div className="hero">
        <img src={hero} />
      </div>
      <div className="content">
        <div className="thank-you-tile">
          <h2>Thank You.</h2>
          <p>We look forward to helping you do the biggest job on earth.</p>
        </div>
      </div>
    </div>
  )
}

export default GivingThanksFormConfirmation;