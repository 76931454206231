import React from "react";

const Checkbox = ({ label, value, onChange, name, id }) => {
  return (
    <label className="checkbox">
      <input
        type="checkbox"
        checked={value}
        onChange={onChange}
        name={name}
        id={id}
      />
      {label}
    </label>
  );
};

export default Checkbox;
