import React, { useEffect } from "react";
import ReactPlayer from "react-player";
import "../src/CSS/videoPlayer.css";
import Layout from "./Layout";
import { useLocation, useNavigate } from "react-router-dom";
import { useIdleTimer } from "react-idle-timer";

const VideoPlayer = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const delay = 7

  const videoLink = location.state.videolink;

  const handleOnIdle = (event) => {
    navigate("/");
  };

  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: 1000 * 60 * delay,
    onIdle: handleOnIdle,
    debounce: 500,
  });

  return (
    <Layout showHeader={true} slug={"play-video"}>
      <div className="video">
        <ReactPlayer
          url={videoLink}
          playing={true}
          controls={true}
          loop={true}
          muted={true}
          playsinline={true}
          height="100%"
          width="100%"
        />
      </div>
    </Layout>
  );
};

export default VideoPlayer;
