import React from "react";
import "./CSS/exportHtml.css";
import { useEffect, useState } from "react";
import axios from "axios";
import CsvDownloadButton from "react-json-to-csv";

const ExportCSV = () => {
  const [sdsDataDict, setSdsDataDict] = useState({});
  // const [scnDataDict, setScnDataDict] = useState([]);

  const [tarSpotDict, setTarSpotData] = useState({});
  const [waterHempDict, setWaterHemp] = useState({});

  let token = "wBs2SNG-fgDqRtJbbAXznPm6OGtzGz_rC12PAld0pGk";

  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const URL =
    "https://api.netlify.com/api/v1/forms/640059c6e0a54a0008d17c27/submissions";

  useEffect(() => {
    let ignore = false;
    let allDataDict;

    axios
      .get(URL, config)
      .then((response) => {
        if (!ignore) {
          allDataDict = MapApiDataToState(response.data);
          setTarSpotData(allDataDict[0]);
          setWaterHemp(allDataDict[1]);
          setSdsDataDict(allDataDict[2]);
        }
      })
      .catch((error) => {
        console.log("error " + error);
      });

    return () => {
      ignore = true;
    };
  }, []);

  const MapApiDataToState = (data) => {
    // let tarSpotHeaders = { state: [], county: [], tarSpot: [] };

    const tarSpotColor = "#004A96";
    const tarSpotLabel = "Live Mapping Tar Spot Pressure";
    let tarSpotPathList = [];

    const waterHempColor = "#C50022";
    const waterHempLabel = "Live Mapping Tar Spot Pressure";
    let waterHempPathList = [];

    const sdnColor = "#00793A";
    let sdnPathList = [];
    const sdnLabel = "Live Mapping SCN Pressure SDS Pressure";

    const scnColor = "#65AC1E";
    let scnPathList = [];
    const scnLabel = "Live Mapping SCN Pressure SDS Pressure";

    const bothsdnScnColor = "#F39500";

    data.map((entry) => {
      let state = entry.data.state;
      let county = entry.data.county;
      let secondCounty = entry.data.county2 ? entry.data.county2 : null;
      let thirdCounty = entry.data.county3 ? entry.data.county3 : null;

      let tarSpot = entry.data["tar-spot-hidden"];
      let waterHemp = entry.data["water-hemp-hidden"];
      let sds = entry.data["sds-hidden"];
      let scn = entry.data["scn-hidden"];

      tarSpotPathList.push(
        ...getCountyStateDataForMap(
          tarSpot,
          county,
          secondCounty,
          thirdCounty,
          state
        )
      );

      waterHempPathList.push(
        ...getCountyStateDataForMap(
          waterHemp,
          county,
          secondCounty,
          thirdCounty,
          state
        )
      );

      scnPathList.push(
        ...getCountyStateDataForMap(
          scn,
          county,
          secondCounty,
          thirdCounty,
          state
        )
      );

      sdnPathList.push(
        ...getCountyStateDataForMap(
          sds,
          county,
          secondCounty,
          thirdCounty,
          state
        )
      );
    });

    let tarSpotMainDict = getFinalValuesForMap(
      tarSpotPathList,
      tarSpotColor,
      tarSpotLabel,
      "Tar Spot"
    );

    let waterHempMainDict = getFinalValuesForMap(
      waterHempPathList,
      waterHempColor,
      waterHempLabel,
      "Waterhemp"
    );

    let scnMainDict = getFinalValuesForMap(
      scnPathList,
      scnColor,
      scnLabel,
      "SCN"
    );

    let sdnMainDict = getFinalValuesForMap(
      sdnPathList,
      sdnColor,
      sdnLabel,
      "SDS"
    );

    // Logic when both the counties intersect (if they intersect)
    let cropsPathIntersect = scnPathList.filter((value) =>
      sdnPathList.includes(value)
    );
    let finalGroup;

    if (cropsPathIntersect.length > 0) {
      let bothCropsMainDict = getFinalValuesForMap(
        cropsPathIntersect,
        bothsdnScnColor,
        scnLabel,
        "SDS and SCN"
      );
      finalGroup = Object.assign(
        {},
        sdnMainDict.groups,
        scnMainDict.groups,
        bothCropsMainDict.groups
      );
    } else {
      finalGroup = Object.assign({}, sdnMainDict.groups, scnMainDict.groups);
    }

    //merged both the crops value into one.
    let cropsFinalValue ={}
    cropsFinalValue["groups"] = finalGroup;

    return [tarSpotMainDict, waterHempMainDict, cropsFinalValue];
  };

  const getCountyStateDataForMap = (
    mapValue,
    county,
    secondCounty,
    thirdCounty,
    state
  ) => {
    let pathValueList = [];
    if (mapValue == "YES") {
      let splitCounties = county.split(" ").join("_");
      let pathValue = `${splitCounties}__${state}`;
      pathValueList.push(pathValue);

      if (secondCounty) {
        splitCounties = secondCounty.split(" ").join("_");
        pathValue = `${splitCounties}__${state}`;
        pathValueList.push(pathValue);
      }
      if (thirdCounty) {
        splitCounties = thirdCounty.split(" ").join("_");
        pathValue = `${splitCounties}__${state}`;
        pathValueList.push(pathValue);
      }
    }
    return pathValueList;
  };

  const getFinalValuesForMap = (
    listOfCountiesState,
    color,
    legend,
    subLabel
  ) => {
    let subDict = {};
    subDict["div"] = "#box0";
    subDict["label"] = subLabel;
    subDict["paths"] = listOfCountiesState;

    let dict = {};
    let mainDict = {};
    dict[color] = subDict;
    mainDict["groups"] = dict;
    mainDict["Title"] = legend;
    mainDict["borders"] = "#000000";
    return mainDict;
  };

  const exportUserInfo = (data) => {
    const fileData = JSON.stringify(data);
    const blob = new Blob([fileData], { type: "text/plain" });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.download = "data.txt";
    link.href = url;
    link.click();
  };

  return (
    <div className="map-table">
      <table>
        <tbody>
          <tr>
            <th>Download Tar Spot Map</th>
            <th>Download Waterhemp Map</th>
            <th>Download SDS and SCN Map</th>
          </tr>
          <tr>
            <td>
              <button onClick={() => exportUserInfo(tarSpotDict)}>
                Download CSV{" "}
              </button>
            </td>
            <td>
              <button onClick={() => exportUserInfo(waterHempDict)}>
                Download CSV{" "}
              </button>
            </td>
            <td>
              <button onClick={() => exportUserInfo(sdsDataDict)}>
                Download CSV{" "}
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default ExportCSV;
