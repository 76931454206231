import React from "react";
import FooterComponent from "./footer";
import HeaderComponent from "./header";
import { useState } from "react";
import "./CSS/layout.css";

const Layout = ({
  children,
  showHeader,
  slug,
  dontShowFooter,
  animatedBg = ["", ""],
}) => {
  const [bgEnabled, bgURL] = animatedBg;

  return (
    <div
      className={"main-content " + slug + bgEnabled}
      style={{ backgroundImage: `url(${bgURL})` }}
    >
      {showHeader ? <HeaderComponent></HeaderComponent> : <div></div>}

      {children}
      {dontShowFooter ? <div></div> : <FooterComponent></FooterComponent>}
    </div>
  );
};

export default Layout;
