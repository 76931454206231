import React from "react";
import "./CSS/portal.css";
import { useNavigate, Navigate } from "react-router-dom";

const RepPortal = () => {
  const navigate = useNavigate();

  const goToMapForm = () => {
    navigate("/map-form");
  }
  const goToContactForm = () => {
    navigate("/contact-form");
  }
  const goToExportExcel = () => {
    navigate("/export-excel");
  }
  const goToFinalReport = () => {
    navigate("/final-report");
  }
  return (
    <div className="rep-portal">
      <h1>Portal</h1>
      <div onClick={goToMapForm}>Map Form</div>
      <br></br>
      <div onClick={goToContactForm}>Contact Form</div>
      <br></br>
      <div onClick={goToExportExcel}>Export Map Data</div>
      <br></br>
      <div onClick={goToFinalReport}>Final Report</div>
    </div>
  )
};

export default RepPortal;