import React from "react";


const FinalReport = () => {

  return (
    <div className="final-report">
      <h1>Final Report</h1>
      <h2>UNDER CONSTRUCTION</h2>
    </div>
  )
};

export default FinalReport;