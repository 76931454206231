import React from "react";
import "../src/CSS/videoPlayer.css";
import Layout from "./Layout";
import "./CSS/iframe.css";
import { useLocation, useNavigate } from "react-router-dom";
import { useIdleTimer } from "react-idle-timer";

const IframeComponent = () => {
  const location = useLocation();

  const iframeLink = location.state.iframeLink;
  const loopingBg = require("../src/v02_BASF_CommClassicVideos_ambientField_JS.gif");
  const navigate = useNavigate();

  const delay = 7

  const handleOnIdle = (event) => {
    navigate("/");
  };

  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: 1000 * 60 * delay,
    onIdle: handleOnIdle,
    debounce: 500,
  });

  return (
    <Layout
      animatedBg={[" bg-enabled", loopingBg]}
      showHeader={true}
      slug="tools"
    >
      <div className="iframe">
        <iframe src={iframeLink}> </iframe>
      </div>
    </Layout>
  );
};

export default IframeComponent;
