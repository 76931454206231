import React from "react";
import Checkbox from "./checkBox";
import "./CSS/contactForm.css";
import { useState } from "react";
import stateLabelValues from "./stateValues";
import { useNavigate } from "react-router-dom";

const ContactFrom = () => {
  const hero = require("../src/contact-form-hero.jpg");

  const navigate = useNavigate();

  const [plantsCheckBoxDict, setPlantCheckBoxDict] = useState({});
  const [plantCheckBoxValidation, setPlantCheckBoxValidation] = useState(true);
  const [learnAboutCheckBoxDict, setLearnaboutCheckBoxDict] = useState({});
  const [learnAboutCheckBoxValidation, setLearnaboutCheckBoxValidation] =
    useState(true);

  document.title = "Contact Us";

  const [privacyPolicyCheckbox, setPrivacyPolicy] = useState({
    condition: true,
    initial: true,
  });

  const [isEmailValidated, setEmailValidation] = useState({
    condition: true,
    initial: true,
  });

  const [isPhoneNumberValidated, setPhoneNumberValidation] = useState({
    condition: true,
    initial: true,
  });

  const [isZipcodeValidate, setZipCodeValidation] = useState({
    condition: true,
    initial: true,
  });

  const [isFirstNameValid, setFirstNameValidation] = useState({
    condition: true,
    initial: true,
  });

  const [isLastNameValid, setLastNameValidation] = useState({
    condition: true,
    initial: true,
  });

  const [isCityValid, setCityValidation] = useState({
    condition: true,
    initial: true,
  });

  const [isStateValid, setStateValidation] = useState({
    condition: true,
    initial: true,
  });

  const [isAddressValid, setAddressValid] = useState({
    condition: true,
    initial: true,
  });

  const handleSubmit = (e) => {
    //check firstName
    let needFirstNameValidation = true;
    if (!isFirstNameValid.initial && !isFirstNameValid.condition) {
      needFirstNameValidation = false;
    }

    setFirstNameValidation({
      condition: needFirstNameValidation,
      initial: false,
    });

    //Last Name Validation

    let needLastNameValidation = true;

    if (!isLastNameValid.initial && !isLastNameValid.condition) {
      needLastNameValidation = false;
    }

    setLastNameValidation({
      condition: needLastNameValidation,
      initial: false,
    });

    //Check Adddress

    let needAddressValidation = true;

    if (!isAddressValid.initial && !isAddressValid.condition) {
      needAddressValidation = false;
    }

    setAddressValid({
      condition: needAddressValidation,
      initial: false,
    });

    //check City

    let needCityValidation = true;

    if (!isCityValid.initial && !isCityValid.condition) {
      needCityValidation = false;
    }

    setCityValidation({
      condition: needCityValidation,
      initial: false,
    });

    // check state

    let needStateValidation = true;

    if (!isStateValid.initial && !isStateValid.condition) {
      needStateValidation = false;
    }
    setStateValidation({
      condition: needStateValidation,
      initial: false,
    });

    // Mailing adddress

    //check if atleast oneCheckBox is for plant
    let isPlantCheckBoxValid =
      returnCheckBoxValidationCriteria(plantsCheckBoxDict);
    setPlantCheckBoxValidation(isPlantCheckBoxValid);

    //check if atleadt one field is checked
    let isFieldCheckBoxValid = returnCheckBoxValidationCriteria(
      learnAboutCheckBoxDict
    );
    setLearnaboutCheckBoxValidation(isFieldCheckBoxValid);

    //Privacy Policy Check
    let needPrivacyPolicyValidation = true;
    if (!privacyPolicyCheckbox.initial && !privacyPolicyCheckbox.condition) {
      needPrivacyPolicyValidation = false;
    }
    setPrivacyPolicy({
      condition: needPrivacyPolicyValidation,
      initial: false,
    });

    //Email validation

    let needEmailValidation = true;

    if (!isEmailValidated.initial && !isEmailValidated.condition) {
      needEmailValidation = false;
    }

    setEmailValidation({ condition: needEmailValidation, initial: false });

    //Phone Number validation

    let needPhoneValidation = true;

    if (!isPhoneNumberValidated.initial && !isPhoneNumberValidated.condition) {
      needPhoneValidation = false;
    }

    setPhoneNumberValidation({
      condition: needPhoneValidation,
      initial: false,
    });

    //zipCodeValidation

    let needZipCodeValidation = true;

    if (!isZipcodeValidate.initial && !isZipcodeValidate.condition) {
      needZipCodeValidation = false;
    }

    setZipCodeValidation({ condition: needZipCodeValidation, initial: false });

    if (
      !(
        needFirstNameValidation ||
        needLastNameValidation ||
        needEmailValidation ||
        needAddressValidation ||
        needCityValidation ||
        needStateValidation ||
        needZipCodeValidation ||
        needPhoneValidation ||
        !plantCheckBoxValidation ||
        !learnAboutCheckBoxValidation
      )
    ) {
      e.preventDefault();
      

      const myForm = e.target;
      const formData = new FormData(myForm);
      let refinedFormdata = {};
      for (var [key, value] of formData.entries()) {
        refinedFormdata[key] = value;
      }


      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({
          "form-name": e.target.getAttribute("name"),
          ...refinedFormdata
        }),
      })
        .then(() => navigate("/thanks/"))
        .catch((error) => console.log(error));
    }

    e.preventDefault();
   
    return false;
  };

  const encode = (data) => {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
      )
      .join("&");
      
  };

  const onNameChange = (e) => {
    let name = e.target.value;
    let validName = () => {
      return !name || name.length === 0;
    };

    let temp = {};
    temp.condition = validName();
    temp.initial = false;

    if (e.target.id === "firstName") {
      setFirstNameValidation(temp);
    } else if (e.target.id === "lastName") {
      setLastNameValidation(temp);
    } else if (e.target.id === "city") {
      setCityValidation(temp);
    } else {
      setAddressValid(temp);
    }
  };

  //handles the logic so that atleast one checkbox is checked
  const onChangePlantCheckBox = (e) => {
    let tempDict = plantsCheckBoxDict;
    tempDict[e.target.id] = e.target.checked;
    setPlantCheckBoxDict(tempDict);
    let containsAtLeastOneChecked =
      returnCheckBoxValidationCriteria(plantsCheckBoxDict);
    setPlantCheckBoxValidation(containsAtLeastOneChecked);
  };

  const onChangeLearnAboutCheckBox = (e) => {
    let tempDict = learnAboutCheckBoxDict;
    tempDict[e.target.id] = e.target.checked;
    setLearnaboutCheckBoxDict(tempDict);
    let containsAtLeastOneChecked = returnCheckBoxValidationCriteria(
      learnAboutCheckBoxDict
    );
    setLearnaboutCheckBoxValidation(containsAtLeastOneChecked);
  };

  // const onChangePrivacyPolicy = (e) => {
  //   let checked = e.target.checked;
  //   let temp = {};
  //   temp.condition = !checked;
  //   temp.initial = false;
  //   setPrivacyPolicy(temp);
  // };

  const onEmailChange = (e) => {
    const re =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    let isEmailvalid = re.test(e.target.value);
    let temp = {};
    temp.condition = !isEmailvalid;
    temp.initial = false;

    setEmailValidation(temp);
  };

  const onPhoneNumberChange = (e) => {
    const re = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/gim;

    let validPhone = re.test(e.target.value);
    let temp = {};
    temp.condition = !validPhone;
    temp.initial = false;
    setPhoneNumberValidation(temp);
  };

  const onStateChange = (e) => {
    let validState = e.target.value.length > 0;
    let temp = {};
    temp.condition = !validState;
    temp.initial = false;
    setStateValidation(temp);
  };

  const onZipCodeChange = (e) => {
    const re = /(^\d{5}$)|(^\d{5}-\d{4}$)/;
    let validZip = re.test(e.target.value);
    let temp = {};
    //not operator because , if the regex is good, we dont need validation.
    // Require Validation only if the regex comes out to be false.
    temp.condition = !validZip;
    temp.initial = false;
    setZipCodeValidation(temp);
  };

  const returnCheckBoxValidationCriteria = (checkBoxDict) => {
    for (let key in checkBoxDict) {
      if (checkBoxDict[key] === true) {
        return true;
      }
    }
    return false;
  };

  return (
    <div className="contact-form">
      <div className="hero">
        <img src={hero} />
      </div>

      <div className="content">
        <h3>
          Fill out this form for more information or to have your local rep
          contact you.
        </h3>
        <p>*Required fields.</p>

        <form
          name="contact"
          data-netlify="true"
          method="POST"
          onSubmit={handleSubmit}
        >
          <input type="hidden" name="form-name" value="contact" />

          <div className="question">
            <div className="text-input-group">
              <div className="form-field first-name">
                <input
                  id="firstName"
                  type="text"
                  name="first"
                  placeholder="FIRST NAME*"
                  onChange={onNameChange}
                 
                ></input>

                <span
                  className={
                    isFirstNameValid.condition && !isFirstNameValid.initial
                      ? "validation-error"
                      : "no-validation-error"
                  }
                >
                  *Please enter a valid first name
                </span>
              </div>

              <div className="form-field last-name">
                <input
                  type="text"
                  id="lastName"
                  name="last"
                  placeholder="LAST NAME*"
                 
                  onChange={onNameChange}
                ></input>

                <span
                  className={
                    isLastNameValid.condition && !isLastNameValid.initial
                      ? "validation-error"
                      : "no-validation-error"
                  }
                >
                  *Please enter a valid last name
                </span>
              </div>

              <div className="form-field farm-name">
                <input
                  type="text"
                  name="farm-name"
                  placeholder="FARM NAME (If Applicable)"
                ></input>
              </div>

              <div className="form-field address">
                <input
                  type="text"
                  id="address"
                  name="address"
                  onChange={onNameChange}
                  placeholder="MAILING ADDRESS (NO P.O. BOXES)*"
                 
                ></input>

                <span
                  className={
                    isAddressValid.condition && !isAddressValid.initial
                      ? "validation-error"
                      : "no-validation-error"
                  }
                >
                  *Please enter a valid mailing address
                </span>
              </div>

              <div className="form-field city">
                <input
                  id="city"
                  type="text"
                  name="city"
                  placeholder="CITY*"
                  onChange={onNameChange}
                 
                ></input>

                <span
                  className={
                    isCityValid.condition && !isCityValid.initial
                      ? "validation-error"
                      : "no-validation-error"
                  }
                >
                  *Please enter a valid city
                </span>
              </div>

              <div className="form-field select state">
                <div className="select-wrapper">
                  <select
                    id="state-dropdown"
                    name="state"
                    onChange={onStateChange}
                   
                  >
                    <option value="">STATE*</option>
                    {stateLabelValues.map((state) => {
                      return <option value={state.value}>{state.label}</option>;
                    })}
                  </select>
                </div>
                <span
                  className={
                    isStateValid.condition && !isStateValid.initial
                      ? "validation-error"
                      : "no-validation-error"
                  }
                >
                  *Please select a state
                </span>
              </div>

              <div className="form-field zipcode">
                <input
                  type="text"
                  name="zipcode"
                  placeholder="ZIP CODE*"
                  onChange={onZipCodeChange}
                 
                ></input>
                <span
                  className={
                    isZipcodeValidate.condition && !isZipcodeValidate.initial
                      ? "validation-error"
                      : "no-validation-error"
                  }
                >
                  *Please enter a valid US zip code
                </span>
              </div>

              <div className="form-field number phone-number">
                <input
                  onChange={onPhoneNumberChange}
                  type="number"
                  name="phone-number"
                  placeholder="BUSINESS PHONE NUMBER*"
                ></input>
                <span
                  className={
                    isPhoneNumberValidated.condition &&
                    !isPhoneNumberValidated.initial
                      ? "validation-error"
                      : "no-validation-error"
                  }
                >
                  *Please enter a valid phone number
                </span>
              </div>

              <div className="form-field email">
                <input
                  onChange={onEmailChange}
                  type="text"
                  name="email"
                  placeholder="BUSINESS EMAIL ADDRESS*"
                 
                ></input>
                <span
                  className={
                    isEmailValidated.condition && !isEmailValidated.initial
                      ? "validation-error"
                      : "no-validation-error"
                  }
                >
                  *Please enter a valid email
                </span>
              </div>
            </div>
          </div>

          <div className="question">
            <label>PREFERRED METHOD OF CONTACT*</label>
            <div className="select-wrapper">
              <select name="preferred-method">
                <option>EMAIL</option>
                <option>PHONE NUMBER</option>
              </select>
            </div>
          </div>

          <div className="question">
            <label>
              WHICH CROP(S) ARE YOU MOST INTERESTED IN? (CHECK ALL THAT APPLY)
            </label>
            <span
              className={
                plantCheckBoxValidation
                  ? "no-validation-error"
                  : "validation-error"
              }
            >
              *Please select at least one option
            </span>
            <div id="crops-checkbox" className="checkbox-group">
              <Checkbox
                id="corn"
                label="Corn"
                name="corn"
                onChange={onChangePlantCheckBox}
              ></Checkbox>
              <Checkbox
                id="wheat"
                label="Wheat"
                name="wheat"
                onChange={onChangePlantCheckBox}
              ></Checkbox>
              <Checkbox
                id="soy"
                name="soy"
                label="Soy"
                onChange={onChangePlantCheckBox}
              ></Checkbox>
              <Checkbox
                name="sorghum"
                id="Sorghum"
                label="Sorghum"
                onChange={onChangePlantCheckBox}
              ></Checkbox>
              <Checkbox
                name="unknown"
                id="Unknown"
                label="Unknown"
                onChange={onChangePlantCheckBox}
              ></Checkbox>
            </div>

            <label>Other (fill in the text box)</label>
            <textarea name="Crops_Text_Box" rows="4" cols="50"></textarea>
          </div>

          <div className="question">
            <label>
              WHAT WOULD YOU LIKE TO LEARN MORE ABOUT? (CHECK ALL THAT APPLY)
            </label>
            <span
              className={
                learnAboutCheckBoxValidation
                  ? "no-validation-error"
                  : "validation-error"
              }
            >
              *Please select at least one option
            </span>
            <div className="checkbox-group">
              <Checkbox
                name="Fungicides"
                id="Fungicides"
                label="Fungicides"
                onChange={onChangeLearnAboutCheckBox}
              ></Checkbox>
              <Checkbox
                name="Seed"
                id="Seed"
                label="Seed"
                onChange={onChangeLearnAboutCheckBox}
              ></Checkbox>
              <Checkbox
                name="Digital Farming Tools"
                id="Digital Farming Tools"
                label="Digital Farming Tools"
                onChange={onChangeLearnAboutCheckBox}
              ></Checkbox>
              <Checkbox
                name="Herbicides"
                id="Herbicides"
                label="Herbicides"
                onChange={onChangeLearnAboutCheckBox}
              ></Checkbox>
              <Checkbox
                name="Seed Treatment"
                id="Seed Treatment"
                label="Seed Treatment"
                onChange={onChangeLearnAboutCheckBox}
              ></Checkbox>
            </div>
            <label>Other (fill in the text box)</label>
            <textarea name="learn-more-text-box" rows="4" cols="50"></textarea>
          </div>

          <div className="question">
            <p className="disclaimer">
              All information collected will be treated in accordance with our{" "}
              <a
                href="https://www.basf.com/us/en/legal/data-protection.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </a>
              . Please see our{" "}
              <a
                href="https://www.basf.com/us/en/legal/data-protection.html#text-1150390576"
                target="_blank"
                rel="noopener noreferrer"
              >
                CCPA Notice of Collection
              </a>{" "}
              as well.
            </p>
            {/* <div
              className={
                privacyPolicyCheckbox.condition &&
                !privacyPolicyCheckbox.initial
                  ? "validation-error"
                  : "no-validation-error"
              }
            >
              *Please check the privacy policy before submitting.
            </div> */}
            {/* <Checkbox
              id="privacy-policy"
              onChange={onChangePrivacyPolicy}
              label={
                "I read and agree with the rules standard language box to click before one can submit"
              }
            ></Checkbox> */}
          </div>

          <input type="submit" value="SUBMIT" />
        </form>
      </div>

      {/* <footer>
        Notice Under California Consumer Privacy Act ("CCPA") <br></br>Please be
        aware that by registering with BASF to learn more about products and
        services, downloading a mobile application, signing up for a platform or
        online service, making purchases, or initiating other business
        transactions or requests with BASF, BASF will be collecting certain
        personal information about you and your internet activity for specific
        business purposes. For a description of the information collected about
        you and the business purposes for which that information is used, please
        review BASF's Privacy Policy, which may be accessed here:&nbsp;
        <a
          href="https://www.basf.com/us/en/legal/data-protection.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://www.basf.com/us/en/legal/data-protection.html
        </a>
      </footer> */}
    </div>
  );
};

export default ContactFrom;
