import { useRef, useState } from "react";
import "./CSS/gluChallenge.css";

const GluChallenge = (props) => {
    const QUESTIONS = [
        {
            number: 1,
            options: {
                a: 'OPTION A',
                b: 'OPTION B'
            },
            correctAnswer: 'B'
        },
        {
            number: 2,
            options: {
                a: 'OPTION A',
                b: 'OPTION B'
            },
            correctAnswer: 'B'
        },
        {
            number: 3,
            options: {
                a: 'OPTION A',
                b: 'OPTION B'
            },
            correctAnswer: 'B'
        },
        {
            number: 4,
            options: {
                a: 'OPTION A',
                b: 'OPTION B'
            },
            correctAnswer: 'B'
        },
    ];

    const [step, setStep] = useState(0);
    const [answers, setAnswers] = useState([]);
    const [thisQuestionAnswered, setThisQuestionAnswered] = useState(null);
    
    // return either 0 or 1
    const rollRandomOrder = () => {
        return Math.floor(Math.random() * 2);
    };

    const [randomOrder, setRandomOrder] = useState(rollRandomOrder());

    const [quizActive, setQuizActive] = useState(false);
    const [quizFinished, setQuizFinished] = useState(false);
    
    const containerRef = useRef();
    const optionARef = useRef();
    const optionBRef = useRef();

    const startQuiz = () => {
        setStep(1);
        setQuizActive(true);
    }

    const restartQuiz = () => {
        window.location.reload();
    }

    const countCorrect = () => {
        let numCorrect = 0;
        answers.forEach((answer, index) => {
            if (answer === QUESTIONS[index].correctAnswer) {
                numCorrect++;
            }
        });
        return numCorrect;
    }

    const onQuestionAnswered = (answer) => {
        const correctAnswer = QUESTIONS[step - 1].correctAnswer;
        let answersUpdate = answers;

        setThisQuestionAnswered(answer);
        if (correctAnswer === 'A') {
            optionARef.current.classList.add('highlight');
            optionBRef.current.classList.remove('highlight');
        } else if (correctAnswer === 'B') {
            optionARef.current.classList.remove('highlight');
            optionBRef.current.classList.add('highlight');
        }

        answersUpdate.push(answer);
        setAnswers(answersUpdate);

        if (answer !== correctAnswer) {
            containerRef.current.classList.add('shake');
        }
    }

    const gotoNextQuestion = () => {
        optionARef.current.classList.remove('highlight');
        optionBRef.current.classList.remove('highlight');
        containerRef.current.classList.remove('shake');
        setThisQuestionAnswered(null);
        if (step === QUESTIONS.length) {
            setQuizFinished(true);
            setQuizActive(false);
        }
        setRandomOrder(rollRandomOrder());
        setStep(step + 1);
    }

    return(
        <div className="glu-challenge" ref={containerRef}>
            <div style={{color: 'magenta'}}>ANSWERS: {JSON.stringify(answers)}</div>

            { !quizActive && !quizFinished &&
            <>
                <h2>Instructions!</h2>
                <button onClick={() => startQuiz()}>Go!</button>
            </>
            }
            { quizActive && !quizFinished &&
            <>
                <div className={`options options--order-${randomOrder}`}>
                    <div 
                        className="option option--a" 
                        ref={optionARef}
                        onClick={() => !thisQuestionAnswered && onQuestionAnswered('A')}
                    >
                        {QUESTIONS[step - 1].options.a}
                    </div>    
                    <div 
                        className="option option--b" 
                        ref={optionBRef}
                        onClick={() => !thisQuestionAnswered && onQuestionAnswered('B')}
                    >
                        {QUESTIONS[step - 1].options.b}
                    </div>    
                </div>
                {
                    thisQuestionAnswered &&
                    <>
                        {(thisQuestionAnswered === QUESTIONS[step - 1].correctAnswer) &&
                            <>
                                That's correct!
                            </>
                        }
                        {(thisQuestionAnswered !== QUESTIONS[step - 1].correctAnswer) &&
                            <>
                                Sorry, the correct answer is {QUESTIONS[step - 1].correctAnswer}!
                            </>
                        }
                        <button onClick={() => gotoNextQuestion() }>Next!</button>
                    </>
                }
            </>
            }
            {
                !quizActive && quizFinished &&
                <>
                    <p>You've completed the challenge with {countCorrect()} correct answers!</p>
                    <button onClick={() => restartQuiz()}>Restart!</button>
                </>
            }
            
        </div>
    )
}

export default GluChallenge;