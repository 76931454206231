const countyDict = [
  { AK: "Aleutians East" },
  { AK: "Aleutians West" },
  { AK: "Anchorage" },
  { AK: "Bethel" },
  { AK: "Bristol Bay" },
  { AK: "Copper River" },
  { AK: "Denali" },
  { AK: "Dillingham" },
  { AK: "Fairbanks North Star" },
  { AK: "Haines" },
  { AK: "Hoonah Angoon" },
  { AK: "Juneau" },
  { AK: "Kenai Peninsula" },
  { AK: "Ketchikan Gateway" },
  { AK: "Kodiak Island" },
  { AK: "Lake and Peninsula" },
  { AK: "Matanuska Susitna" },
  { AK: "Nome" },
  { AK: "North Slope" },
  { AK: "Northwest Arctic" },
  { AK: "Petersburg" },
  { AK: "Prince of Wales Hyder" },
  { AK: "SE Fairbanks" },
  { AK: "Sitka" },
  { AK: "Skagway" },
  { AK: "Valdez Cordova" },
  { AK: "Kusilvak" },
  { AK: "Wrangell" },
  { AK: "Yakutat" },
  { AK: "Yukon Koyukuk" },
  { AL: "Autauga" },
  { AL: "Autauga" },
  { AL: "Baldwin" },
  { AL: "Barbour" },
  { AL: "Bibb" },
  { AL: "Blount" },
  { AL: "Bullock" },
  { AL: "Butler" },
  { AL: "Calhoun" },
  { AL: "Chambers" },
  { AL: "Cherokee" },
  { AL: "Chilton" },
  { AL: "Choctaw" },
  { AL: "Clarke" },
  { AL: "Clay" },
  { AL: "Cleburne" },
  { AL: "Coffee" },
  { AL: "Colbert" },
  { AL: "Conecuh" },
  { AL: "Coosa" },
  { AL: "Covington" },
  { AL: "Crenshaw" },
  { AL: "Cullman" },
  { AL: "Dale" },
  { AL: "Dallas" },
  { AL: "DeKalb" },
  { AL: "Elmore" },
  { AL: "Escambia" },
  { AL: "Etowah" },
  { AL: "Fayette" },
  { AL: "Franklin" },
  { AL: "Geneva" },
  { AL: "Greene" },
  { AL: "Hale" },
  { AL: "Henry" },
  { AL: "Houston" },
  { AL: "Jackson" },
  { AL: "Jefferson" },
  { AL: "Lamar" },
  { AL: "Lauderdale" },
  { AL: "Lawrence" },
  { AL: "Lee" },
  { AL: "Limestone" },
  { AL: "Lowndes" },
  { AL: "Macon" },
  { AL: "Madison" },
  { AL: "Marengo" },
  { AL: "Marion" },
  { AL: "Marshall" },
  { AL: "Mobile" },
  { AL: "Monroe" },
  { AL: "Montgomery" },
  { AL: "Morgan" },
  { AL: "Perry" },
  { AL: "Pickens" },
  { AL: "Pike" },
  { AL: "Randolph" },
  { AL: "Russell" },
  { AL: "Shelby" },
  { AL: "St  Clair" },
  { AL: "Sumter" },
  { AL: "Talladega" },
  { AL: "Tallapoosa" },
  { AL: "Tuscaloosa" },
  { AL: "Walker" },
  { AL: "Washington" },
  { AL: "Wilcox" },
  { AL: "Winston" },
  { AR: "Arkansas" },
  { AR: "Ashley" },
  { AR: "Baxter" },
  { AR: "Benton" },
  { AR: "Boone" },
  { AR: "Bradley" },
  { AR: "Calhoun" },
  { AR: "Carroll" },
  { AR: "Chicot" },
  { AR: "Clark" },
  { AR: "Clay" },
  { AR: "Cleburne" },
  { AR: "Cleveland" },
  { AR: "Columbia" },
  { AR: "Conway" },
  { AR: "Craighead" },
  { AR: "Crawford" },
  { AR: "Crittenden" },
  { AR: "Cross" },
  { AR: "Dallas" },
  { AR: "Desha" },
  { AR: "Drew" },
  { AR: "Faulkner" },
  { AR: "Franklin" },
  { AR: "Fulton" },
  { AR: "Garland" },
  { AR: "Grant" },
  { AR: "Greene" },
  { AR: "Hempstead" },
  { AR: "Hot Spring" },
  { AR: "Howard" },
  { AR: "Independence" },
  { AR: "Izard" },
  { AR: "Jackson" },
  { AR: "Jefferson" },
  { AR: "Johnson" },
  { AR: "Lafayette" },
  { AR: "Lawrence" },
  { AR: "Lee" },
  { AR: "Lincoln" },
  { AR: "Little River" },
  { AR: "Logan" },
  { AR: "Lonoke" },
  { AR: "Madison" },
  { AR: "Marion" },
  { AR: "Miller" },
  { AR: "Mississippi" },
  { AR: "Monroe" },
  { AR: "Montgomery" },
  { AR: "Nevada" },
  { AR: "Newton" },
  { AR: "Ouachita" },
  { AR: "Perry" },
  { AR: "Phillips" },
  { AR: "Pike" },
  { AR: "Poinsett" },
  { AR: "Polk" },
  { AR: "Pope" },
  { AR: "Prairie" },
  { AR: "Pulaski" },
  { AR: "Randolph" },
  { AR: "Saline" },
  { AR: "Scott" },
  { AR: "Searcy" },
  { AR: "Sebastian" },
  { AR: "Sevier" },
  { AR: "Sharp" },
  { AR: "St  Francis" },
  { AR: "Stone" },
  { AR: "Union" },
  { AR: "Van Buren" },
  { AR: "Washington" },
  { AR: "White" },
  { AR: "Woodruff" },
  { AR: "Yell" },
  { AZ: "Apache" },
  { AZ: "Cochise" },
  { AZ: "Coconino" },
  { AZ: "Gila" },
  { AZ: "Graham" },
  { AZ: "Greenlee" },
  { AZ: "La Paz" },
  { AZ: "Maricopa" },
  { AZ: "Mohave" },
  { AZ: "Navajo" },
  { AZ: "Pima" },
  { AZ: "Pinal" },
  { AZ: "Santa Cruz" },
  { AZ: "Yavapai" },
  { AZ: "Yuma" },
  { CA: "Alameda" },
  { CA: "Alpine" },
  { CA: "Amador" },
  { CA: "Butte" },
  { CA: "Calaveras" },
  { CA: "Colusa" },
  { CA: "Contra Costa" },
  { CA: "Del Norte" },
  { CA: "El Dorado" },
  { CA: "Fresno" },
  { CA: "Glenn" },
  { CA: "Humboldt" },
  { CA: "Imperial" },
  { CA: "Inyo" },
  { CA: "Kern" },
  { CA: "Kings" },
  { CA: "Lake" },
  { CA: "Lassen" },
  { CA: "Los Angeles" },
  { CA: "Madera" },
  { CA: "Marin" },
  { CA: "Mariposa" },
  { CA: "Mendocino" },
  { CA: "Merced" },
  { CA: "Modoc" },
  { CA: "Mono" },
  { CA: "Monterey" },
  { CA: "Napa" },
  { CA: "Nevada" },
  { CA: "Orange" },
  { CA: "Placer" },
  { CA: "Plumas" },
  { CA: "Riverside" },
  { CA: "Sacramento" },
  { CA: "San Benito" },
  { CA: "San Bernardino" },
  { CA: "San Diego" },
  { CA: "San Francisco" },
  { CA: "San Joaquin" },
  { CA: "San Luis Obispo" },
  { CA: "San Mateo" },
  { CA: "Santa Barbara" },
  { CA: "Santa Clara" },
  { CA: "Santa Cruz" },
  { CA: "Shasta" },
  { CA: "Sierra" },
  { CA: "Siskiyou" },
  { CA: "Solano" },
  { CA: "Sonoma" },
  { CA: "Stanislaus" },
  { CA: "Sutter" },
  { CA: "Tehama" },
  { CA: "Trinity" },
  { CA: "Tulare" },
  { CA: "Tuolumne" },
  { CA: "Ventura" },
  { CA: "Yolo" },
  { CA: "Yuba" },
  { CO: "Adams" },
  { CO: "Alamosa" },
  { CO: "Arapahoe" },
  { CO: "Archuleta" },
  { CO: "Baca" },
  { CO: "Bent" },
  { CO: "Boulder" },
  { CO: "Broomfield" },
  { CO: "Chaffee" },
  { CO: "Cheyenne" },
  { CO: "Clear Creek" },
  { CO: "Conejos" },
  { CO: "Costilla" },
  { CO: "Crowley" },
  { CO: "Custer" },
  { CO: "Delta" },
  { CO: "Denver" },
  { CO: "Dolores" },
  { CO: "Douglas" },
  { CO: "Eagle" },
  { CO: "El Paso" },
  { CO: "Elbert" },
  { CO: "Fremont" },
  { CO: "Garfield" },
  { CO: "Gilpin" },
  { CO: "Grand" },
  { CO: "Gunnison" },
  { CO: "Hinsdale" },
  { CO: "Huerfano" },
  { CO: "Jackson" },
  { CO: "Jefferson" },
  { CO: "Kiowa" },
  { CO: "Kit Carson" },
  { CO: "La Plata" },
  { CO: "Lake" },
  { CO: "Larimer" },
  { CO: "Las Animas" },
  { CO: "Lincoln" },
  { CO: "Logan" },
  { CO: "Mesa" },
  { CO: "Mineral" },
  { CO: "Moffat" },
  { CO: "Montezuma" },
  { CO: "Montrose" },
  { CO: "Morgan" },
  { CO: "Otero" },
  { CO: "Ouray" },
  { CO: "Park" },
  { CO: "Phillips" },
  { CO: "Pitkin" },
  { CO: "Prowers" },
  { CO: "Pueblo" },
  { CO: "Rio Blanco" },
  { CO: "Rio Grande" },
  { CO: "Routt" },
  { CO: "Saguache" },
  { CO: "San Juan" },
  { CO: "San Miguel" },
  { CO: "Sedgwick" },
  { CO: "Summit" },
  { CO: "Teller" },
  { CO: "Washington" },
  { CO: "Weld" },
  { CO: "Yuma" },
  { CT: "Fairfield" },
  { CT: "Hartford" },
  { CT: "Litchfield" },
  { CT: "Middlesex" },
  { CT: "New Haven" },
  { CT: "New London" },
  { CT: "Tolland" },
  { CT: "Windham" },
  { DC: "Washington" },
  { DE: "Kent" },
  { DE: "New Castle" },
  { DE: "Sussex" },
  { FL: "Alachua" },
  { FL: "Baker" },
  { FL: "Bay" },
  { FL: "Bradford" },
  { FL: "Brevard" },
  { FL: "Broward" },
  { FL: "Calhoun" },
  { FL: "Charlotte" },
  { FL: "Citrus" },
  { FL: "Clay" },
  { FL: "Collier" },
  { FL: "Columbia" },
  { FL: "DeSoto" },
  { FL: "Dixie" },
  { FL: "Duval" },
  { FL: "Escambia" },
  { FL: "Flagler" },
  { FL: "Franklin" },
  { FL: "Gadsden" },
  { FL: "Gilchrist" },
  { FL: "Glades" },
  { FL: "Gulf" },
  { FL: "Hamilton" },
  { FL: "Hardee" },
  { FL: "Hendry" },
  { FL: "Hernando" },
  { FL: "Highlands" },
  { FL: "Hillsborough" },
  { FL: "Holmes" },
  { FL: "Indian River" },
  { FL: "Jackson" },
  { FL: "Jefferson" },
  { FL: "Lafayette" },
  { FL: "Lake" },
  { FL: "Lee" },
  { FL: "Leon" },
  { FL: "Levy" },
  { FL: "Liberty" },
  { FL: "Madison" },
  { FL: "Manatee" },
  { FL: "Marion" },
  { FL: "Martin" },
  { FL: "Miami Dade" },
  { FL: "Monroe" },
  { FL: "Nassau" },
  { FL: "Okaloosa" },
  { FL: "Okeechobee" },
  { FL: "Orandge" },
  { FL: "Osceola" },
  { FL: "Palm Beach" },
  { FL: "Pasco" },
  { FL: "Pinellas" },
  { FL: "Polk" },
  { FL: "Putnam" },
  { FL: "Santa Rosa" },
  { FL: "Sarasota" },
  { FL: "Seminole" },
  { FL: "St  Johns" },
  { FL: "St  Lucie" },
  { FL: "Sumter" },
  { FL: "Suwannee" },
  { FL: "Taylor" },
  { FL: "Union" },
  { FL: "Volusia" },
  { FL: "Wakulla" },
  { FL: "Walton" },
  { FL: "Washington" },
  { GA: "Appling" },
  { GA: "Atkinson" },
  { GA: "Bacon" },
  { GA: "Baker" },
  { GA: "Baldwin" },
  { GA: "Banks" },
  { GA: "Barrow" },
  { GA: "Bartow" },
  { GA: "Ben Hill" },
  { GA: "Berrien" },
  { GA: "Bibb" },
  { GA: "Bleckley" },
  { GA: "Brantley" },
  { GA: "Brooks" },
  { GA: "Bryan" },
  { GA: "Bulloch" },
  { GA: "Burke" },
  { GA: "Butts" },
  { GA: "Calhoun" },
  { GA: "Camden" },
  { GA: "Candler" },
  { GA: "Carroll" },
  { GA: "Catoosa" },
  { GA: "Charlton" },
  { GA: "Chatham" },
  { GA: "Chattahoochee" },
  { GA: "Chattooga" },
  { GA: "Cherokee" },
  { GA: "Clarke" },
  { GA: "Clay" },
  { GA: "Clayton" },
  { GA: "Clinch" },
  { GA: "Cobb" },
  { GA: "Coffee" },
  { GA: "Colquitt" },
  { GA: "Columbia" },
  { GA: "Cook" },
  { GA: "Coweta" },
  { GA: "Crawford" },
  { GA: "Crisp" },
  { GA: "Dade" },
  { GA: "Dawson" },
  { GA: "Decatur" },
  { GA: "DeKalb" },
  { GA: "Dodge" },
  { GA: "Dooly" },
  { GA: "Dougherty" },
  { GA: "Douglas" },
  { GA: "Early" },
  { GA: "Echols" },
  { GA: "Effingham" },
  { GA: "Elbert" },
  { GA: "Emanuel" },
  { GA: "Evans" },
  { GA: "Fannin" },
  { GA: "Fayette" },
  { GA: "Floyd" },
  { GA: "Forsyth" },
  { GA: "Franklin" },
  { GA: "Fulton" },
  { GA: "Gilmer" },
  { GA: "Glascock" },
  { GA: "Glynn" },
  { GA: "Gordon" },
  { GA: "Grady" },
  { GA: "Greene" },
  { GA: "Gwinnett" },
  { GA: "Habersham" },
  { GA: "Hall" },
  { GA: "Hancock" },
  { GA: "Haralson" },
  { GA: "Harris" },
  { GA: "Hart" },
  { GA: "Heard" },
  { GA: "Henry" },
  { GA: "Houston" },
  { GA: "Irwin" },
  { GA: "Jackson" },
  { GA: "Jasper" },
  { GA: "Jeff Davis" },
  { GA: "Jefferson" },
  { GA: "Jenkins" },
  { GA: "Johnson" },
  { GA: "Jones" },
  { GA: "Lamar" },
  { GA: "Lanier" },
  { GA: "Laurens" },
  { GA: "Lee" },
  { GA: "Liberty" },
  { GA: "Lincoln" },
  { GA: "Long" },
  { GA: "Lowndes" },
  { GA: "Lumpkin" },
  { GA: "Macon" },
  { GA: "Madison" },
  { GA: "Marion" },
  { GA: "McDuffie" },
  { GA: "McIntosh" },
  { GA: "Meriwether" },
  { GA: "Miller" },
  { GA: "Mitchell" },
  { GA: "Monroe" },
  { GA: "Montgomery" },
  { GA: "Morgan" },
  { GA: "Murray" },
  { GA: "Muscogee" },
  { GA: "Newton" },
  { GA: "Oconee" },
  { GA: "Oglethorpe" },
  { GA: "Paulding" },
  { GA: "Peach" },
  { GA: "Pickens" },
  { GA: "Pierce" },
  { GA: "Pike" },
  { GA: "Polk" },
  { GA: "Pulaski" },
  { GA: "Putnam" },
  { GA: "Quitman" },
  { GA: "Rabun" },
  { GA: "Randolph" },
  { GA: "Richmond" },
  { GA: "Rockdale" },
  { GA: "Schley" },
  { GA: "Screven" },
  { GA: "Seminole" },
  { GA: "Spalding" },
  { GA: "Stephens" },
  { GA: "Stewart" },
  { GA: "Sumter" },
  { GA: "Talbot" },
  { GA: "Taliaferro" },
  { GA: "Tattnall" },
  { GA: "Taylor" },
  { GA: "Telfair" },
  { GA: "Terrell" },
  { GA: "Thomas" },
  { GA: "Tift" },
  { GA: "Toombs" },
  { GA: "Towns" },
  { GA: "Treutlen" },
  { GA: "Troup" },
  { GA: "Turner" },
  { GA: "Twiggs" },
  { GA: "Union" },
  { GA: "Upson" },
  { GA: "Walker" },
  { GA: "Walton" },
  { GA: "Ware" },
  { GA: "Warren" },
  { GA: "Washington" },
  { GA: "Wayne" },
  { GA: "Webster" },
  { GA: "Wheeler" },
  { GA: "White" },
  { GA: "Whitfield" },
  { GA: "Wilcox" },
  { GA: "Wilkes" },
  { GA: "Wilkinson" },
  { GA: "Worth" },
  { HI: "Hawaii" },
  { HI: "Honolulu" },
  { HI: "Kalawao" },
  { HI: "Kauai" },
  { HI: "Maui" },
  { IA: "Adair" },
  { IA: "Adams" },
  { IA: "Allamakee" },
  { IA: "Appanoose" },
  { IA: "Audubon" },
  { IA: "Benton" },
  { IA: "Black Hawk" },
  { IA: "Boone" },
  { IA: "Bremer" },
  { IA: "Buchanan" },
  { IA: "Buena Vista" },
  { IA: "Butler" },
  { IA: "Calhoun" },
  { IA: "Carroll" },
  { IA: "Cass" },
  { IA: "Cedar" },
  { IA: "Cerro Gordo" },
  { IA: "Cherokee" },
  { IA: "Chickasaw" },
  { IA: "Clarke" },
  { IA: "Clay" },
  { IA: "Clayton" },
  { IA: "Clinton" },
  { IA: "Crawford" },
  { IA: "Dallas" },
  { IA: "Davis" },
  { IA: "Decatur" },
  { IA: "Delaware" },
  { IA: "Des Moines" },
  { IA: "Dickinson" },
  { IA: "Dubuque" },
  { IA: "Emmet" },
  { IA: "Fayette" },
  { IA: "Floyd" },
  { IA: "Franklin" },
  { IA: "Fremont" },
  { IA: "Greene" },
  { IA: "Grundy" },
  { IA: "Guthrie" },
  { IA: "Hamilton" },
  { IA: "Hancock" },
  { IA: "Hardin" },
  { IA: "Harrison" },
  { IA: "Henry" },
  { IA: "Howard" },
  { IA: "Humboldt" },
  { IA: "Ida" },
  { IA: "Iowa" },
  { IA: "Jackson" },
  { IA: "Jasper" },
  { IA: "Jefferson" },
  { IA: "Johnson" },
  { IA: "Jones" },
  { IA: "Keokuk" },
  { IA: "Kossuth" },
  { IA: "Lee" },
  { IA: "Linn" },
  { IA: "Louisa" },
  { IA: "Lucas" },
  { IA: "Lyon" },
  { IA: "Madison" },
  { IA: "Mahaska" },
  { IA: "Marion" },
  { IA: "Marshall" },
  { IA: "Mills" },
  { IA: "Mitchell" },
  { IA: "Monona" },
  { IA: "Monroe" },
  { IA: "Montgomery" },
  { IA: "Muscatine" },
  { IA: "O Brien" },
  { IA: "Osceola" },
  { IA: "Page" },
  { IA: "Palo Alto" },
  { IA: "Plymouth" },
  { IA: "Pocahontas" },
  { IA: "Polk" },
  { IA: "Pottawattamie" },
  { IA: "Poweshiek" },
  { IA: "Ringgold" },
  { IA: "Sac" },
  { IA: "Scott" },
  { IA: "Shelby" },
  { IA: "Sioux" },
  { IA: "Story" },
  { IA: "Tama" },
  { IA: "Taylor" },
  { IA: "Union" },
  { IA: "Van Buren" },
  { IA: "Wapello" },
  { IA: "Warren" },
  { IA: "Washington" },
  { IA: "Wayne" },
  { IA: "Webster" },
  { IA: "Winnebago" },
  { IA: "Winneshiek" },
  { IA: "Woodbury" },
  { IA: "Worth" },
  { IA: "Wright" },
  { ID: "Ada" },
  { ID: "Adams" },
  { ID: "Bannock" },
  { ID: "Bear Lake" },
  { ID: "Benewah" },
  { ID: "Bingham" },
  { ID: "Blaine" },
  { ID: "Boise" },
  { ID: "Bonner" },
  { ID: "Bonneville" },
  { ID: "Boundary" },
  { ID: "Butte" },
  { ID: "Camas" },
  { ID: "Canyon" },
  { ID: "Caribou" },
  { ID: "Cassia" },
  { ID: "Clark" },
  { ID: "Clearwater" },
  { ID: "Custer" },
  { ID: "Elmore" },
  { ID: "Franklin" },
  { ID: "Fremont" },
  { ID: "Gem" },
  { ID: "Gooding" },
  { ID: "Idaho" },
  { ID: "Jefferson" },
  { ID: "Jerome" },
  { ID: "Kootenai" },
  { ID: "Latah" },
  { ID: "Lemhi" },
  { ID: "Lewis" },
  { ID: "Lincoln" },
  { ID: "Madison" },
  { ID: "Minidoka" },
  { ID: "Nez Perce" },
  { ID: "Oneida" },
  { ID: "Owyhee" },
  { ID: "Payette" },
  { ID: "Power" },
  { ID: "Shoshone" },
  { ID: "Teton" },
  { ID: "Twin Falls" },
  { ID: "Valley" },
  { ID: "Washington" },
  { IL: "Adams" },
  { IL: "Alexander" },
  { IL: "Bond" },
  { IL: "Boone" },
  { IL: "Brown" },
  { IL: "Bureau" },
  { IL: "Calhoun" },
  { IL: "Carroll" },
  { IL: "Cass" },
  { IL: "Champaign" },
  { IL: "Christian" },
  { IL: "Clark" },
  { IL: "Clay" },
  { IL: "Clinton" },
  { IL: "Coles" },
  { IL: "Cook" },
  { IL: "Crawford" },
  { IL: "Cumberland" },
  { IL: "De Witt" },
  { IL: "DeKalb" },
  { IL: "McDonough" },
  { IL: "Douglas" },
  { IL: "DuPage" },
  { IL: "Edgar" },
  { IL: "Edwards" },
  { IL: "Effingham" },
  { IL: "Fayette" },
  { IL: "Ford" },
  { IL: "Franklin" },
  { IL: "Fulton" },
  { IL: "Gallatin" },
  { IL: "Greene" },
  { IL: "Grundy" },
  { IL: "Hamilton" },
  { IL: "Hancock" },
  { IL: "Hardin" },
  { IL: "Henderson" },
  { IL: "Henry" },
  { IL: "Iroquois" },
  { IL: "Jackson" },
  { IL: "Jasper" },
  { IL: "Jefferson" },
  { IL: "Jersey" },
  { IL: "Jo Daviess" },
  { IL: "Johnson" },
  { IL: "Kane" },
  { IL: "Kankakee" },
  { IL: "Kendall" },
  { IL: "Knox" },
  { IL: "La Salle" },
  { IL: "Lake" },
  { IL: "Lawrence" },
  { IL: "Lee" },
  { IL: "Livingston" },
  { IL: "Logan" },
  { IL: "Macon" },
  { IL: "Macoupin" },
  { IL: "Madison" },
  { IL: "Marion" },
  { IL: "Marshall" },
  { IL: "Mason" },
  { IL: "Massac" },
  { IL: "McHenry" },
  { IL: "McLean" },
  { IL: "Menard" },
  { IL: "Mercer" },
  { IL: "Monroe" },
  { IL: "Montgomery" },
  { IL: "Morgan" },
  { IL: "Moultrie" },
  { IL: "Ogle" },
  { IL: "Peoria" },
  { IL: "Perry" },
  { IL: "Piatt" },
  { IL: "Pike" },
  { IL: "Pope" },
  { IL: "Pulaski" },
  { IL: "Putnam" },
  { IL: "Randolph" },
  { IL: "Richland" },
  { IL: "Rock Island" },
  { IL: "Saline" },
  { IL: "Sangamon" },
  { IL: "Schuyler" },
  { IL: "Scott" },
  { IL: "Shelby" },
  { IL: "St  Clair" },
  { IL: "Stark" },
  { IL: "Stephenson" },
  { IL: "Tazewell" },
  { IL: "Union" },
  { IL: "Vermilion" },
  { IL: "Wabash" },
  { IL: "Warren" },
  { IL: "Washington" },
  { IL: "Wayne" },
  { IL: "White" },
  { IL: "Whiteside" },
  { IL: "Will" },
  { IL: "Williamson" },
  { IL: "Winnebago" },
  { IL: "Woodford" },
  { IN: "Adams" },
  { IN: "Allen" },
  { IN: "Bartholomew" },
  { IN: "Benton" },
  { IN: "Blackford" },
  { IN: "Boone" },
  { IN: "Brown" },
  { IN: "Carroll" },
  { IN: "Cass" },
  { IN: "Clark" },
  { IN: "Clay" },
  { IN: "Clinton" },
  { IN: "Crawford" },
  { IN: "Daviess" },
  { IN: "Dearborn" },
  { IN: "Decatur" },
  { IN: "DeKalb" },
  { IN: "Delaware" },
  { IN: "Dubois" },
  { IN: "Elkhart" },
  { IN: "Fayette" },
  { IN: "Floyd" },
  { IN: "Fountain" },
  { IN: "Franklin" },
  { IN: "Fulton" },
  { IN: "Gibson" },
  { IN: "Grant" },
  { IN: "Greene" },
  { IN: "Hamilton" },
  { IN: "Hancock" },
  { IN: "Harrison" },
  { IN: "Hendricks" },
  { IN: "Henry" },
  { IN: "Howard" },
  { IN: "Huntington" },
  { IN: "Jackson" },
  { IN: "Jasper" },
  { IN: "Jay" },
  { IN: "Jefferson" },
  { IN: "Jennings" },
  { IN: "Johnson" },
  { IN: "Knox" },
  { IN: "Kosciusko" },
  { IN: "LaGrange" },
  { IN: "Lake" },
  { IN: "LaPorte" },
  { IN: "Lawrence" },
  { IN: "Madison" },
  { IN: "Marion" },
  { IN: "Marshall" },
  { IN: "Martin" },
  { IN: "Miami" },
  { IN: "Monroe" },
  { IN: "Montgomery" },
  { IN: "Morgan" },
  { IN: "Newton" },
  { IN: "Noble" },
  { IN: "Ohio" },
  { IN: "Orange" },
  { IN: "Owen" },
  { IN: "Parke" },
  { IN: "Perry" },
  { IN: "Pike" },
  { IN: "Porter" },
  { IN: "Posey" },
  { IN: "Pulaski" },
  { IN: "Putnam" },
  { IN: "Randolph" },
  { IN: "Ripley" },
  { IN: "Rush" },
  { IN: "Scott" },
  { IN: "Shelby" },
  { IN: "Spencer" },
  { IN: "St  Joseph" },
  { IN: "Starke" },
  { IN: "Steuben" },
  { IN: "Sullivan" },
  { IN: "Switzerland" },
  { IN: "Tippecanoe" },
  { IN: "Tipton" },
  { IN: "Union" },
  { IN: "Vanderburgh" },
  { IN: "Vermillion" },
  { IN: "Vigo" },
  { IN: "Wabash" },
  { IN: "Warren" },
  { IN: "Warrick" },
  { IN: "Washington" },
  { IN: "Wayne" },
  { IN: "Wells" },
  { IN: "White" },
  { IN: "Whitley" },
  { KS: "Allen" },
  { KS: "Anderson" },
  { KS: "Atchison" },
  { KS: "Barber" },
  { KS: "Barton" },
  { KS: "Bourbon" },
  { KS: "Brown" },
  { KS: "Butler" },
  { KS: "Chase" },
  { KS: "Chautauqua" },
  { KS: "Cherokee" },
  { KS: "Cheyenne" },
  { KS: "Clark" },
  { KS: "Clay" },
  { KS: "Cloud" },
  { KS: "Coffey" },
  { KS: "Comanche" },
  { KS: "Cowley" },
  { KS: "Crawford" },
  { KS: "Decatur" },
  { KS: "Dickinson" },
  { KS: "Doniphan" },
  { KS: "Douglas" },
  { KS: "Edwards" },
  { KS: "Elk" },
  { KS: "Ellis" },
  { KS: "Ellsworth" },
  { KS: "Finney" },
  { KS: "Ford" },
  { KS: "Franklin" },
  { KS: "Geary" },
  { KS: "Gove" },
  { KS: "Graham" },
  { KS: "Grant" },
  { KS: "Gray" },
  { KS: "Greeley" },
  { KS: "Greenwood" },
  { KS: "Hamilton" },
  { KS: "Harper" },
  { KS: "Harvey" },
  { KS: "Haskell" },
  { KS: "Hodgeman" },
  { KS: "Jackson" },
  { KS: "Jefferson" },
  { KS: "Jewell" },
  { KS: "Johnson" },
  { KS: "Kearny" },
  { KS: "Kingman" },
  { KS: "Kiowa" },
  { KS: "Labette" },
  { KS: "Lane" },
  { KS: "Leavenworth" },
  { KS: "Lincoln" },
  { KS: "Linn" },
  { KS: "Logan" },
  { KS: "Lyon" },
  { KS: "Marion" },
  { KS: "Marshall" },
  { KS: "McPherson" },
  { KS: "Meade" },
  { KS: "Miami" },
  { KS: "Mitchell" },
  { KS: "Montgomery" },
  { KS: "Morris" },
  { KS: "Morton" },
  { KS: "Nemaha" },
  { KS: "Neosho" },
  { KS: "Ness" },
  { KS: "Norton" },
  { KS: "Osage" },
  { KS: "Osborne" },
  { KS: "Ottawa" },
  { KS: "Pawnee" },
  { KS: "Phillips" },
  { KS: "Pottawatomie" },
  { KS: "Pratt" },
  { KS: "Rawlins" },
  { KS: "Reno" },
  { KS: "Republic" },
  { KS: "Rice" },
  { KS: "Riley" },
  { KS: "Rooks" },
  { KS: "Rush" },
  { KS: "Russell" },
  { KS: "Saline" },
  { KS: "Scott" },
  { KS: "Sedgwick" },
  { KS: "Seward" },
  { KS: "Shawnee" },
  { KS: "Sheridan" },
  { KS: "Sherman" },
  { KS: "Smith" },
  { KS: "Stafford" },
  { KS: "Stanton" },
  { KS: "Stevens" },
  { KS: "Sumner" },
  { KS: "Thomas" },
  { KS: "Trego" },
  { KS: "Wabaunsee" },
  { KS: "Wallace" },
  { KS: "Washington" },
  { KS: "Wichita" },
  { KS: "Wilson" },
  { KS: "Woodson" },
  { KS: "Wyandotte" },
  { KY: "Adair" },
  { KY: "Allen" },
  { KY: "Anderson" },
  { KY: "Ballard" },
  { KY: "Barren" },
  { KY: "Bath" },
  { KY: "Bell" },
  { KY: "Boone" },
  { KY: "Bourbon" },
  { KY: "Boyd" },
  { KY: "Boyle" },
  { KY: "Bracken" },
  { KY: "Breathitt" },
  { KY: "Breckinridge" },
  { KY: "Bullitt" },
  { KY: "Butler" },
  { KY: "Caldwell" },
  { KY: "Calloway" },
  { KY: "Campbell" },
  { KY: "Carlisle" },
  { KY: "Carroll" },
  { KY: "Carter" },
  { KY: "Casey" },
  { KY: "Christian" },
  { KY: "Clark" },
  { KY: "Clay" },
  { KY: "Clinton" },
  { KY: "Crittenden" },
  { KY: "Cumberland" },
  { KY: "Daviess" },
  { KY: "Edmonson" },
  { KY: "Elliott" },
  { KY: "Estill" },
  { KY: "Fayette" },
  { KY: "Fleming" },
  { KY: "Floyd" },
  { KY: "Franklin" },
  { KY: "Fulton" },
  { KY: "Gallatin" },
  { KY: "Garrard" },
  { KY: "Grant" },
  { KY: "Graves" },
  { KY: "Grayson" },
  { KY: "Green" },
  { KY: "Greenup" },
  { KY: "Hancock" },
  { KY: "Hardin" },
  { KY: "Harlan" },
  { KY: "Harrison" },
  { KY: "Hart" },
  { KY: "Henderson" },
  { KY: "Henry" },
  { KY: "Hickman" },
  { KY: "Hopkins" },
  { KY: "Jackson" },
  { KY: "Jefferson" },
  { KY: "Jessamine" },
  { KY: "Johnson" },
  { KY: "Kenton" },
  { KY: "Knott" },
  { KY: "Knox" },
  { KY: "Larue" },
  { KY: "Laurel" },
  { KY: "Lawrence" },
  { KY: "Lee" },
  { KY: "Leslie" },
  { KY: "Letcher" },
  { KY: "Lewis" },
  { KY: "Lincoln" },
  { KY: "Livingston" },
  { KY: "Logan" },
  { KY: "Lyon" },
  { KY: "Madison" },
  { KY: "Magoffin" },
  { KY: "Marion" },
  { KY: "Marshall" },
  { KY: "Martin" },
  { KY: "Mason" },
  { KY: "McCracken" },
  { KY: "McCreary" },
  { KY: "McLean" },
  { KY: "Meade" },
  { KY: "Menifee" },
  { KY: "Mercer" },
  { KY: "Metcalfe" },
  { KY: "Monroe" },
  { KY: "Montgomery" },
  { KY: "Morgan" },
  { KY: "Muhlenberg" },
  { KY: "Nelson" },
  { KY: "Nicholas" },
  { KY: "Ohio" },
  { KY: "Oldham" },
  { KY: "Owen" },
  { KY: "Owsley" },
  { KY: "Pendleton" },
  { KY: "Perry" },
  { KY: "Pike" },
  { KY: "Powell" },
  { KY: "Pulaski" },
  { KY: "Robertson" },
  { KY: "Rockcastle" },
  { KY: "Rowan" },
  { KY: "Russell" },
  { KY: "Scott" },
  { KY: "Shelby" },
  { KY: "Simpson" },
  { KY: "Spencer" },
  { KY: "Taylor" },
  { KY: "Todd" },
  { KY: "Trigg" },
  { KY: "Trimble" },
  { KY: "Union" },
  { KY: "Warren" },
  { KY: "Washington" },
  { KY: "Wayne" },
  { KY: "Webster" },
  { KY: "Whitley" },
  { KY: "Wolfe" },
  { KY: "Woodford" },
  { LA: "Acadia" },
  { LA: "Allen" },
  { LA: "Ascension" },
  { LA: "Assumption" },
  { LA: "Avoyelles" },
  { LA: "Beauregard" },
  { LA: "Bienville" },
  { LA: "Bossier" },
  { LA: "Caddo" },
  { LA: "Calcasieu" },
  { LA: "Caldwell" },
  { LA: "Cameron" },
  { LA: "Catahoula" },
  { LA: "Claiborne" },
  { LA: "Concordia" },
  { LA: "De Soto" },
  { LA: "East Baton Rouge" },
  { LA: "East Carroll" },
  { LA: "East Feliciana" },
  { LA: "Evangeline" },
  { LA: "Franklin" },
  { LA: "Grant" },
  { LA: "Iberia" },
  { LA: "Iberville" },
  { LA: "Jackson" },
  { LA: "Jefferson" },
  { LA: "Jefferson Davis" },
  { LA: "LaSalle" },
  { LA: "Lafayette" },
  { LA: "Lafourche" },
  { LA: "Lincoln" },
  { LA: "Livingston" },
  { LA: "Madison" },
  { LA: "Morehouse" },
  { LA: "Natchitoches" },
  { LA: "Orleans" },
  { LA: "Ouachita" },
  { LA: "Plaquemines" },
  { LA: "Pointe Coupee" },
  { LA: "Rapides" },
  { LA: "Red River" },
  { LA: "Richland" },
  { LA: "Sabine" },
  { LA: "St  Bernard" },
  { LA: "St  Charles" },
  { LA: "St  Helena" },
  { LA: "St  James" },
  { LA: "St  John the Baptist" },
  { LA: "St  Landry" },
  { LA: "St  Martin" },
  { LA: "St  Mary" },
  { LA: "St  Tammany" },
  { LA: "Tangipahoa" },
  { LA: "Tensas" },
  { LA: "Terrebonne" },
  { LA: "Union" },
  { LA: "Vermilion" },
  { LA: "Vernon" },
  { LA: "Washington" },
  { LA: "Webster" },
  { LA: "West Baton Rouge" },
  { LA: "West Carroll" },
  { LA: "West Feliciana" },
  { LA: "Winn" },
  { MA: "Barnstable" },
  { MA: "Berkshire" },
  { MA: "Bristol" },
  { MA: "Dukes" },
  { MA: "Essex" },
  { MA: "Franklin" },
  { MA: "Hampden" },
  { MA: "Hampshire" },
  { MA: "Middlesex" },
  { MA: "Nantucket" },
  { MA: "Norfolk" },
  { MA: "Plymouth" },
  { MA: "Suffolk" },
  { MA: "Worcester" },
  { MD: "Allegany" },
  { MD: "Anne Arundel" },
  { MD: "Baltimore City" },
  { MD: "Baltimore County" },
  { MD: "Calvert" },
  { MD: "Caroline" },
  { MD: "Carroll" },
  { MD: "Cecil" },
  { MD: "Charles" },
  { MD: "Dorchester" },
  { MD: "Frederick" },
  { MD: "Garrett" },
  { MD: "Harford" },
  { MD: "Howard" },
  { MD: "Kent" },
  { MD: "Montgomery" },
  { MD: "Prince George s" },
  { MD: "Queen Anne s" },
  { MD: "Somerset" },
  { MD: "St Mary s" },
  { MD: "Talbot" },
  { MD: "Washington" },
  { MD: "Wicomico" },
  { MD: "Worcester" },
  { ME: "Androscoggin" },
  { ME: "Aroostook" },
  { ME: "Cumberland" },
  { ME: "Franklin" },
  { ME: "Hancock" },
  { ME: "Kennebec" },
  { ME: "Knox" },
  { ME: "Lincoln" },
  { ME: "Oxford" },
  { ME: "Penobscot" },
  { ME: "Piscataquis" },
  { ME: "Sagadahoc" },
  { ME: "Somerset" },
  { ME: "Waldo" },
  { ME: "Washington" },
  { ME: "York" },
  { MI: "Alcona" },
  { MI: "Alger" },
  { MI: "Allegan" },
  { MI: "Alpena" },
  { MI: "Antrim" },
  { MI: "Arenac" },
  { MI: "Baraga" },
  { MI: "Barry" },
  { MI: "Bay" },
  { MI: "Benzie" },
  { MI: "Berrien" },
  { MI: "Branch" },
  { MI: "Calhoun" },
  { MI: "Cass" },
  { MI: "Charlevoix" },
  { MI: "Cheboygan" },
  { MI: "Chippewa" },
  { MI: "Clare" },
  { MI: "Clinton" },
  { MI: "Crawford" },
  { MI: "Delta" },
  { MI: "Dickinson" },
  { MI: "Eaton" },
  { MI: "Emmet" },
  { MI: "Genesee" },
  { MI: "Gladwin" },
  { MI: "Gogebic" },
  { MI: "Grand Traverse" },
  { MI: "Gratiot" },
  { MI: "Hillsdale" },
  { MI: "Houghton" },
  { MI: "Huron" },
  { MI: "Ingham" },
  { MI: "Ionia" },
  { MI: "Iosco" },
  { MI: "Iron" },
  { MI: "Isabella" },
  { MI: "Jackson" },
  { MI: "Kalamazoo" },
  { MI: "Kalkaska" },
  { MI: "Kent" },
  { MI: "Keweenaw" },
  { MI: "Lake" },
  { MI: "Lapeer" },
  { MI: "Leelanau" },
  { MI: "Lenawee" },
  { MI: "Livingston" },
  { MI: "Luce" },
  { MI: "Mackinac" },
  { MI: "Macomb" },
  { MI: "Manistee" },
  { MI: "Marquette" },
  { MI: "Mason" },
  { MI: "Mecosta" },
  { MI: "Menominee" },
  { MI: "Midland" },
  { MI: "Missaukee" },
  { MI: "Monroe" },
  { MI: "Montcalm" },
  { MI: "Montmorency" },
  { MI: "Muskegon" },
  { MI: "Newaygo" },
  { MI: "Oakland" },
  { MI: "Oceana" },
  { MI: "Ogemaw" },
  { MI: "Ontonagon" },
  { MI: "Osceola" },
  { MI: "Oscoda" },
  { MI: "Otsego" },
  { MI: "Ottawa" },
  { MI: "Presque Isle" },
  { MI: "Roscommon" },
  { MI: "Saginaw" },
  { MI: "Sanilac" },
  { MI: "Schoolcraft" },
  { MI: "Shiawassee" },
  { MI: "St  Clair" },
  { MI: "St  Joseph" },
  { MI: "Tuscola" },
  { MI: "Van Buren" },
  { MI: "Washtenaw" },
  { MI: "Wayne" },
  { MI: "Wexford" },
  { MN: "Aitkin" },
  { MN: "Anoka" },
  { MN: "Becker" },
  { MN: "Beltrami" },
  { MN: "Benton" },
  { MN: "Big Stone" },
  { MN: "Blue Earth" },
  { MN: "Brown" },
  { MN: "Carlton" },
  { MN: "Carver" },
  { MN: "Cass" },
  { MN: "Chippewa" },
  { MN: "Chisago" },
  { MN: "Clay" },
  { MN: "Clearwater" },
  { MN: "Cook" },
  { MN: "Cottonwood" },
  { MN: "Crow Wing" },
  { MN: "Dakota" },
  { MN: "Dodge" },
  { MN: "Douglas" },
  { MN: "Faribault" },
  { MN: "Fillmore" },
  { MN: "Freeborn" },
  { MN: "Goodhue" },
  { MN: "Grant" },
  { MN: "Hennepin" },
  { MN: "Houston" },
  { MN: "Hubbard" },
  { MN: "Isanti" },
  { MN: "Itasca" },
  { MN: "Jackson" },
  { MN: "Kanabec" },
  { MN: "Kandiyohi" },
  { MN: "Kittson" },
  { MN: "Koochiching" },
  { MN: "Lac qui Parle" },
  { MN: "Lake" },
  { MN: "Lake of the Woods" },
  { MN: "Le Sueur" },
  { MN: "Lincoln" },
  { MN: "Lyon" },
  { MN: "Mahnomen" },
  { MN: "Marshall" },
  { MN: "Martin" },
  { MN: "McLeod" },
  { MN: "Meeker" },
  { MN: "Mille Lacs" },
  { MN: "Morrison" },
  { MN: "Mower" },
  { MN: "Murray" },
  { MN: "Nicollet" },
  { MN: "Nobles" },
  { MN: "Norman" },
  { MN: "Olmsted" },
  { MN: "Otter Tail" },
  { MN: "Pennington" },
  { MN: "Pine" },
  { MN: "Pipestone" },
  { MN: "Polk" },
  { MN: "Pope" },
  { MN: "Ramsey" },
  { MN: "Red Lake" },
  { MN: "Redwood" },
  { MN: "Renville" },
  { MN: "Rice" },
  { MN: "Rock" },
  { MN: "Roseau" },
  { MN: "Scott" },
  { MN: "Sherburne" },
  { MN: "Sibley" },
  { MN: "St  Louis" },
  { MN: "Stearns" },
  { MN: "Steele" },
  { MN: "Stevens" },
  { MN: "Swift" },
  { MN: "Todd" },
  { MN: "Traverse" },
  { MN: "Wabasha" },
  { MN: "Wadena" },
  { MN: "Waseca" },
  { MN: "Washington" },
  { MN: "Watonwan" },
  { MN: "Wilkin" },
  { MN: "Winona" },
  { MN: "Wright" },
  { MN: "Yellow Medicine" },
  { MO: "Adair" },
  { MO: "Andrew" },
  { MO: "Atchison" },
  { MO: "Audrain" },
  { MO: "Barry" },
  { MO: "Barton" },
  { MO: "Bates" },
  { MO: "Benton" },
  { MO: "Bollinger" },
  { MO: "Boone" },
  { MO: "Buchanan" },
  { MO: "Butler" },
  { MO: "Caldwell" },
  { MO: "Callaway" },
  { MO: "Camden" },
  { MO: "Cape Girardeau" },
  { MO: "Carroll" },
  { MO: "Carter" },
  { MO: "Cass" },
  { MO: "Cedar" },
  { MO: "Chariton" },
  { MO: "Christian" },
  { MO: "Clark" },
  { MO: "Clay" },
  { MO: "Clinton" },
  { MO: "Cole" },
  { MO: "Cooper" },
  { MO: "Crawford" },
  { MO: "Dade" },
  { MO: "Dallas" },
  { MO: "Daviess" },
  { MO: "DeKalb" },
  { MO: "Dent" },
  { MO: "Douglas" },
  { MO: "Dunklin" },
  { MO: "Franklin" },
  { MO: "Gasconade" },
  { MO: "Gentry" },
  { MO: "Greene" },
  { MO: "Grundy" },
  { MO: "Harrison" },
  { MO: "Henry" },
  { MO: "Hickory" },
  { MO: "Holt" },
  { MO: "Howard" },
  { MO: "Howell" },
  { MO: "Iron" },
  { MO: "Jackson" },
  { MO: "Jasper" },
  { MO: "Jefferson" },
  { MO: "Johnson" },
  { MO: "Knox" },
  { MO: "Laclede" },
  { MO: "Lafayette" },
  { MO: "Lawrence" },
  { MO: "Lewis" },
  { MO: "Lincoln" },
  { MO: "Linn" },
  { MO: "Livingston" },
  { MO: "Macon" },
  { MO: "Madison" },
  { MO: "Maries" },
  { MO: "Marion" },
  { MO: "McDonald" },
  { MO: "Mercer" },
  { MO: "Miller" },
  { MO: "Mississippi" },
  { MO: "Moniteau" },
  { MO: "Monroe" },
  { MO: "Montgomery" },
  { MO: "Morgan" },
  { MO: "New Madrid" },
  { MO: "Newton" },
  { MO: "Nodaway" },
  { MO: "Oregon" },
  { MO: "Osage" },
  { MO: "Ozark" },
  { MO: "Pemiscot" },
  { MO: "Perry" },
  { MO: "Pettis" },
  { MO: "Phelps" },
  { MO: "Pike" },
  { MO: "Platte" },
  { MO: "Polk" },
  { MO: "Pulaski" },
  { MO: "Putnam" },
  { MO: "Ralls" },
  { MO: "Randolph" },
  { MO: "Ray" },
  { MO: "Reynolds" },
  { MO: "Ripley" },
  { MO: "Sainte Genevieve" },
  { MO: "Saline" },
  { MO: "Schuyler" },
  { MO: "Scotland" },
  { MO: "Scott" },
  { MO: "Shannon" },
  { MO: "Shelby" },
  { MO: "St  Charles" },
  { MO: "St  Clair" },
  { MO: "St  Francois" },
  { MO: "St  Louis" },
  { MO: "St  Louis Co " },
  { MO: "Stoddard" },
  { MO: "Stone" },
  { MO: "Sullivan" },
  { MO: "Taney" },
  { MO: "Texas" },
  { MO: "Vernon" },
  { MO: "Warren" },
  { MO: "Washington" },
  { MO: "Wayne" },
  { MO: "Webster" },
  { MO: "Worth" },
  { MO: "Wright" },
  { MS: "Adams" },
  { MS: "Alcorn" },
  { MS: "Amite" },
  { MS: "Attala" },
  { MS: "Benton" },
  { MS: "Bolivar" },
  { MS: "Calhoun" },
  { MS: "Carroll" },
  { MS: "Chickasaw" },
  { MS: "Choctaw" },
  { MS: "Claiborne" },
  { MS: "Clarke" },
  { MS: "Clay" },
  { MS: "Coahoma" },
  { MS: "Copiah" },
  { MS: "Covington" },
  { MS: "DeSoto" },
  { MS: "Forrest" },
  { MS: "Franklin" },
  { MS: "George" },
  { MS: "Greene" },
  { MS: "Grenada" },
  { MS: "Hancock" },
  { MS: "Harrison" },
  { MS: "Hinds" },
  { MS: "Holmes" },
  { MS: "Humphreys" },
  { MS: "Issaquena" },
  { MS: "Itawamba" },
  { MS: "Jackson" },
  { MS: "Jasper" },
  { MS: "Jefferson" },
  { MS: "Jefferson Davis" },
  { MS: "Jones" },
  { MS: "Kemper" },
  { MS: "Lafayette" },
  { MS: "Lamar" },
  { MS: "Lauderdale" },
  { MS: "Lawrence" },
  { MS: "Leake" },
  { MS: "Lee" },
  { MS: "Leflore" },
  { MS: "Lincoln" },
  { MS: "Lowndes" },
  { MS: "Madison" },
  { MS: "Marion" },
  { MS: "Marshall" },
  { MS: "Monroe" },
  { MS: "Montgomery" },
  { MS: "Neshoba" },
  { MS: "Newton" },
  { MS: "Noxubee" },
  { MS: "Oktibbeha" },
  { MS: "Panola" },
  { MS: "Pearl River" },
  { MS: "Perry" },
  { MS: "Pike" },
  { MS: "Pontotoc" },
  { MS: "Prentiss" },
  { MS: "Quitman" },
  { MS: "Rankin" },
  { MS: "Scott" },
  { MS: "Sharkey" },
  { MS: "Simpson" },
  { MS: "Smith" },
  { MS: "Stone" },
  { MS: "Sunflower" },
  { MS: "Tallahatchie" },
  { MS: "Tate" },
  { MS: "Tippah" },
  { MS: "Tishomingo" },
  { MS: "Tunica" },
  { MS: "Union" },
  { MS: "Walthall" },
  { MS: "Warren" },
  { MS: "Washington" },
  { MS: "Wayne" },
  { MS: "Webster" },
  { MS: "Wilkinson" },
  { MS: "Winston" },
  { MS: "Yalobusha" },
  { MS: "Yazoo" },
  { MT: "Beaverhead" },
  { MT: "Big Horn" },
  { MT: "Blaine" },
  { MT: "Broadwater" },
  { MT: "Carbon" },
  { MT: "Carter" },
  { MT: "Cascade" },
  { MT: "Chouteau" },
  { MT: "Custer" },
  { MT: "Daniels" },
  { MT: "Dawson" },
  { MT: "Deer Lodge" },
  { MT: "Fallon" },
  { MT: "Fergus" },
  { MT: "Flathead" },
  { MT: "Gallatin" },
  { MT: "Garfield" },
  { MT: "Glacier" },
  { MT: "Golden Valley" },
  { MT: "Granite" },
  { MT: "Hill" },
  { MT: "Jefferson" },
  { MT: "Judith Basin" },
  { MT: "Lake" },
  { MT: "Lewis and Clark" },
  { MT: "Liberty" },
  { MT: "Lincoln" },
  { MT: "Madison" },
  { MT: "McCone" },
  { MT: "Meagher" },
  { MT: "Mineral" },
  { MT: "Missoula" },
  { MT: "Musselshell" },
  { MT: "Park" },
  { MT: "Petroleum" },
  { MT: "Phillips" },
  { MT: "Pondera" },
  { MT: "Powder River" },
  { MT: "Powell" },
  { MT: "Prairie" },
  { MT: "Ravalli" },
  { MT: "Richland" },
  { MT: "Roosevelt" },
  { MT: "Rosebud" },
  { MT: "Sanders" },
  { MT: "Sheridan" },
  { MT: "Silver Bow" },
  { MT: "Stillwater" },
  { MT: "Sweet Grass" },
  { MT: "Teton" },
  { MT: "Toole" },
  { MT: "Treasure" },
  { MT: "Valley" },
  { MT: "Wheatland" },
  { MT: "Wibaux" },
  { MT: "Yellowstone" },
  { NC: "Alamance" },
  { NC: "Alexander" },
  { NC: "Alleghany" },
  { NC: "Anson" },
  { NC: "Ashe" },
  { NC: "Avery" },
  { NC: "Beaufort" },
  { NC: "Bertie" },
  { NC: "Bladen" },
  { NC: "Brunswick" },
  { NC: "Buncombe" },
  { NC: "Burke" },
  { NC: "Cabarrus" },
  { NC: "Caldwell" },
  { NC: "Camden" },
  { NC: "Carteret" },
  { NC: "Caswell" },
  { NC: "Catawba" },
  { NC: "Chatham" },
  { NC: "Cherokee" },
  { NC: "Chowan" },
  { NC: "Clay" },
  { NC: "Cleveland" },
  { NC: "Columbus" },
  { NC: "Craven" },
  { NC: "Cumberland" },
  { NC: "Currituck" },
  { NC: "Dare" },
  { NC: "Davidson" },
  { NC: "Davie" },
  { NC: "Duplin" },
  { NC: "Durham" },
  { NC: "Edgecombe" },
  { NC: "Forsyth" },
  { NC: "Franklin" },
  { NC: "Gaston" },
  { NC: "Gates" },
  { NC: "Graham" },
  { NC: "Granville" },
  { NC: "Greene" },
  { NC: "Guilford" },
  { NC: "Halifax" },
  { NC: "Harnett" },
  { NC: "Haywood" },
  { NC: "Henderson" },
  { NC: "Hertford" },
  { NC: "Hoke" },
  { NC: "Hyde" },
  { NC: "Iredell" },
  { NC: "Jackson" },
  { NC: "Johnston" },
  { NC: "Jones" },
  { NC: "Lee" },
  { NC: "Lenoir" },
  { NC: "Lincoln" },
  { NC: "Macon" },
  { NC: "Madison" },
  { NC: "Martin" },
  { NC: "McDowell" },
  { NC: "Mecklenburg" },
  { NC: "Mitchell" },
  { NC: "Montgomery" },
  { NC: "Moore" },
  { NC: "Nash" },
  { NC: "New Hanover" },
  { NC: "Northampton" },
  { NC: "Onslow" },
  { NC: "Orange" },
  { NC: "Pamlico" },
  { NC: "Pasquotank" },
  { NC: "Pender" },
  { NC: "Perquimans" },
  { NC: "Person" },
  { NC: "Pitt" },
  { NC: "Polk" },
  { NC: "Randolph" },
  { NC: "Richmond" },
  { NC: "Robeson" },
  { NC: "Rockingham" },
  { NC: "Rowan" },
  { NC: "Rutherford" },
  { NC: "Sampson" },
  { NC: "Scotland" },
  { NC: "Stanly" },
  { NC: "Stokes" },
  { NC: "Surry" },
  { NC: "Swain" },
  { NC: "Transylvania" },
  { NC: "Tyrrell" },
  { NC: "Union" },
  { NC: "Vance" },
  { NC: "Wake" },
  { NC: "Warren" },
  { NC: "Washington" },
  { NC: "Watauga" },
  { NC: "Wayne" },
  { NC: "Wilkes" },
  { NC: "Wilson" },
  { NC: "Yadkin" },
  { NC: "Yancey" },
  { ND: "Adams" },
  { ND: "Barnes" },
  { ND: "Benson" },
  { ND: "Billings" },
  { ND: "Bottineau" },
  { ND: "Bowman" },
  { ND: "Burke" },
  { ND: "Burleigh" },
  { ND: "Cass" },
  { ND: "Cavalier" },
  { ND: "Dickey" },
  { ND: "Divide" },
  { ND: "Dunn" },
  { ND: "Eddy" },
  { ND: "Emmons" },
  { ND: "Foster" },
  { ND: "Golden Valley" },
  { ND: "Grand Forks" },
  { ND: "Grant" },
  { ND: "Griggs" },
  { ND: "Hettinger" },
  { ND: "Kidder" },
  { ND: "LaMoure" },
  { ND: "Logan" },
  { ND: "McHenry" },
  { ND: "McIntosh" },
  { ND: "McKenzie" },
  { ND: "McLean" },
  { ND: "Mercer" },
  { ND: "Morton" },
  { ND: "Mountrail" },
  { ND: "Nelson" },
  { ND: "Oliver" },
  { ND: "Pembina" },
  { ND: "Pierce" },
  { ND: "Ramsey" },
  { ND: "Ransom" },
  { ND: "Renville" },
  { ND: "Richland" },
  { ND: "Rolette" },
  { ND: "Sargent" },
  { ND: "Sheridan" },
  { ND: "Sioux" },
  { ND: "Slope" },
  { ND: "Stark" },
  { ND: "Steele" },
  { ND: "Stutsman" },
  { ND: "Towner" },
  { ND: "Traill" },
  { ND: "Walsh" },
  { ND: "Ward" },
  { ND: "Wells" },
  { ND: "Williams" },
  { NE: "Adams" },
  { NE: "Antelope" },
  { NE: "Arthur" },
  { NE: "Banner" },
  { NE: "Blaine" },
  { NE: "Boone" },
  { NE: "Box Butte" },
  { NE: "Boyd" },
  { NE: "Brown" },
  { NE: "Buffalo" },
  { NE: "Burt" },
  { NE: "Butler" },
  { NE: "Cass" },
  { NE: "Cedar" },
  { NE: "Chase" },
  { NE: "Cherry" },
  { NE: "Cheyenne" },
  { NE: "Clay" },
  { NE: "Colfax" },
  { NE: "Cuming" },
  { NE: "Custer" },
  { NE: "Dakota" },
  { NE: "Dawes" },
  { NE: "Dawson" },
  { NE: "Deuel" },
  { NE: "Dixon" },
  { NE: "Dodge" },
  { NE: "Douglas" },
  { NE: "Dundy" },
  { NE: "Fillmore" },
  { NE: "Franklin" },
  { NE: "Frontier" },
  { NE: "Furnas" },
  { NE: "Gage" },
  { NE: "Garden" },
  { NE: "Garfield" },
  { NE: "Gosper" },
  { NE: "Grant" },
  { NE: "Greeley" },
  { NE: "Hall" },
  { NE: "Hamilton" },
  { NE: "Harlan" },
  { NE: "Hayes" },
  { NE: "Hitchcock" },
  { NE: "Holt" },
  { NE: "Hooker" },
  { NE: "Howard" },
  { NE: "Jefferson" },
  { NE: "Johnson" },
  { NE: "Kearney" },
  { NE: "Keith" },
  { NE: "Keya Paha" },
  { NE: "Kimball" },
  { NE: "Knox" },
  { NE: "Lancaster" },
  { NE: "Lincoln" },
  { NE: "Logan" },
  { NE: "Loup" },
  { NE: "Madison" },
  { NE: "McPherson" },
  { NE: "Merrick" },
  { NE: "Morrill" },
  { NE: "Nance" },
  { NE: "Nemaha" },
  { NE: "Nuckolls" },
  { NE: "Otoe" },
  { NE: "Pawnee" },
  { NE: "Perkins" },
  { NE: "Phelps" },
  { NE: "Pierce" },
  { NE: "Platte" },
  { NE: "Polk" },
  { NE: "Red Willow" },
  { NE: "Richardson" },
  { NE: "Rock" },
  { NE: "Saline" },
  { NE: "Sarpy" },
  { NE: "Saunders" },
  { NE: "Scotts Bluff" },
  { NE: "Seward" },
  { NE: "Sheridan" },
  { NE: "Sherman" },
  { NE: "Sioux" },
  { NE: "Stanton" },
  { NE: "Thayer" },
  { NE: "Thomas" },
  { NE: "Thurston" },
  { NE: "Valley" },
  { NE: "Washington" },
  { NE: "Wayne" },
  { NE: "Webster" },
  { NE: "Wheeler" },
  { NE: "York" },
  { NH: "Belknap" },
  { NH: "Carroll" },
  { NH: "Cheshire" },
  { NH: "Coos" },
  { NH: "Grafton" },
  { NH: "Hillsborough" },
  { NH: "Merrimack" },
  { NH: "Rockingham" },
  { NH: "Strafford" },
  { NH: "Sullivan" },
  { NJ: "Atlantic" },
  { NJ: "Bergen" },
  { NJ: "Burlington" },
  { NJ: "Camden" },
  { NJ: "Cape May" },
  { NJ: "Cumberland" },
  { NJ: "Essex" },
  { NJ: "Gloucester" },
  { NJ: "Hudson" },
  { NJ: "Hunterdon" },
  { NJ: "Mercer" },
  { NJ: "Middlesex" },
  { NJ: "Monmouth" },
  { NJ: "Morris" },
  { NJ: "Ocean" },
  { NJ: "Passaic" },
  { NJ: "Salem" },
  { NJ: "Somerset" },
  { NJ: "Sussex" },
  { NJ: "Union" },
  { NJ: "Warren" },
  { NM: "Bernalillo" },
  { NM: "Catron" },
  { NM: "Chaves" },
  { NM: "Cibola" },
  { NM: "Colfax" },
  { NM: "Curry" },
  { NM: "De Baca" },
  { NM: "Dona Ana" },
  { NM: "Eddy" },
  { NM: "Grant" },
  { NM: "Guadalupe" },
  { NM: "Harding" },
  { NM: "Hidalgo" },
  { NM: "Lea" },
  { NM: "Lincoln" },
  { NM: "Los Alamos" },
  { NM: "Luna" },
  { NM: "McKinely" },
  { NM: "Mora" },
  { NM: "Otero" },
  { NM: "Quay" },
  { NM: "Rio Arriba" },
  { NM: "Roosevelt" },
  { NM: "San Juan" },
  { NM: "San Miguel" },
  { NM: "Sandoval" },
  { NM: "Santa Fe" },
  { NM: "Sierra" },
  { NM: "Socorro" },
  { NM: "Taos" },
  { NM: "Torrance" },
  { NM: "Union" },
  { NM: "Valencia" },
  { NV: "Carson City" },
  { NV: "Churchill" },
  { NV: "Clark" },
  { NV: "Douglas" },
  { NV: "Elko" },
  { NV: "Esmeralda" },
  { NV: "Eureka" },
  { NV: "Humboldt" },
  { NV: "Lander" },
  { NV: "Lincoln" },
  { NV: "Lyon" },
  { NV: "Mineral" },
  { NV: "Nye" },
  { NV: "Pershing" },
  { NV: "Storey" },
  { NV: "Washoe" },
  { NV: "White Pine" },
  { NY: "Albany" },
  { NY: "Allegany" },
  { NY: "Bronx" },
  { NY: "Broome" },
  { NY: "Cattaraugus" },
  { NY: "Cayuga" },
  { NY: "Chautauqua" },
  { NY: "Chemung" },
  { NY: "Chenango" },
  { NY: "Clinton" },
  { NY: "Columbia" },
  { NY: "Cortland" },
  { NY: "Delaware" },
  { NY: "Dutchess" },
  { NY: "Erie" },
  { NY: "Essex" },
  { NY: "Franklin" },
  { NY: "Fulton" },
  { NY: "Genesee" },
  { NY: "Greene" },
  { NY: "Hamilton" },
  { NY: "Herkimer" },
  { NY: "Jefferson" },
  { NY: "Kings" },
  { NY: "Lewis" },
  { NY: "Livingston" },
  { NY: "Madison" },
  { NY: "Monroe" },
  { NY: "Montgomery" },
  { NY: "Nassau" },
  { NY: "New York" },
  { NY: "Niagara" },
  { NY: "Oneida" },
  { NY: "Onondaga" },
  { NY: "Ontario" },
  { NY: "Orange" },
  { NY: "Orleans" },
  { NY: "Oswego" },
  { NY: "Otsego" },
  { NY: "Putnam" },
  { NY: "Queens" },
  { NY: "Rensselaer" },
  { NY: "Richmond" },
  { NY: "Rockland" },
  { NY: "Saratoga" },
  { NY: "Schenectady" },
  { NY: "Schoharie" },
  { NY: "Schuyler" },
  { NY: "Seneca" },
  { NY: "St  Lawrence" },
  { NY: "Steuben" },
  { NY: "Suffolk" },
  { NY: "Sullivan" },
  { NY: "Tioga" },
  { NY: "Tompkins" },
  { NY: "Ulster" },
  { NY: "Warren" },
  { NY: "Washington" },
  { NY: "Wayne" },
  { NY: "Westchester" },
  { NY: "Wyoming" },
  { NY: "Yates" },
  { OH: "Adams" },
  { OH: "Allen" },
  { OH: "Ashland" },
  { OH: "Ashtabula" },
  { OH: "Athens" },
  { OH: "Auglaize" },
  { OH: "Belmont" },
  { OH: "Brown" },
  { OH: "Butler" },
  { OH: "Carroll" },
  { OH: "Champaign" },
  { OH: "Clark" },
  { OH: "Clermont" },
  { OH: "Clinton" },
  { OH: "Columbiana" },
  { OH: "Coshocton" },
  { OH: "Crawford" },
  { OH: "Cuyahoga" },
  { OH: "Darke" },
  { OH: "Defiance" },
  { OH: "Delaware" },
  { OH: "Erie" },
  { OH: "Fairfield" },
  { OH: "Fayette" },
  { OH: "Franklin" },
  { OH: "Fulton" },
  { OH: "Gallia" },
  { OH: "Geauga" },
  { OH: "Greene" },
  { OH: "Guernsey" },
  { OH: "Hamilton" },
  { OH: "Hancock" },
  { OH: "Hardin" },
  { OH: "Harrison" },
  { OH: "Henry" },
  { OH: "Highland" },
  { OH: "Hocking" },
  { OH: "Holmes" },
  { OH: "Huron" },
  { OH: "Jackson" },
  { OH: "Jefferson" },
  { OH: "Knox" },
  { OH: "Lake" },
  { OH: "Lawrence" },
  { OH: "Licking" },
  { OH: "Logan" },
  { OH: "Lorain" },
  { OH: "Lucas" },
  { OH: "Madison" },
  { OH: "Mahoning" },
  { OH: "Marion" },
  { OH: "Medina" },
  { OH: "Meigs" },
  { OH: "Mercer" },
  { OH: "Miami" },
  { OH: "Monroe" },
  { OH: "Montgomery" },
  { OH: "Morgan" },
  { OH: "Morrow" },
  { OH: "Muskingum" },
  { OH: "Noble" },
  { OH: "Ottawa" },
  { OH: "Paulding" },
  { OH: "Perry" },
  { OH: "Pickaway" },
  { OH: "Pike" },
  { OH: "Portage" },
  { OH: "Preble" },
  { OH: "Putnam" },
  { OH: "Richland" },
  { OH: "Ross" },
  { OH: "Sandusky" },
  { OH: "Scioto" },
  { OH: "Seneca" },
  { OH: "Shelby" },
  { OH: "Stark" },
  { OH: "Summit" },
  { OH: "Trumbull" },
  { OH: "Tuscarawas" },
  { OH: "Union" },
  { OH: "Van Wert" },
  { OH: "Vinton" },
  { OH: "Warren" },
  { OH: "Washington" },
  { OH: "Wayne" },
  { OH: "Williams" },
  { OH: "Wood" },
  { OH: "Wyandot" },
  { OK: "Adair" },
  { OK: "Alfalfa" },
  { OK: "Atoka" },
  { OK: "Beaver" },
  { OK: "Beckham" },
  { OK: "Blaine" },
  { OK: "Bryan" },
  { OK: "Caddo" },
  { OK: "Canadian" },
  { OK: "Carter" },
  { OK: "Cherokee" },
  { OK: "Choctaw" },
  { OK: "Cimarron" },
  { OK: "Cleveland" },
  { OK: "Coal" },
  { OK: "Comanche" },
  { OK: "Cotton" },
  { OK: "Craig" },
  { OK: "Creek" },
  { OK: "Custer" },
  { OK: "Delaware" },
  { OK: "Dewey" },
  { OK: "Ellis" },
  { OK: "Garfield" },
  { OK: "Garvin" },
  { OK: "Grady" },
  { OK: "Grant" },
  { OK: "Greer" },
  { OK: "Harmon" },
  { OK: "Harper" },
  { OK: "Haskell" },
  { OK: "Hughes" },
  { OK: "Jackson" },
  { OK: "Jefferson" },
  { OK: "Johnston" },
  { OK: "Kay" },
  { OK: "Kingfisher" },
  { OK: "Kiowa" },
  { OK: "Latimer" },
  { OK: "Le Flore" },
  { OK: "Lincoln" },
  { OK: "Logan" },
  { OK: "Love" },
  { OK: "Major" },
  { OK: "Marshall" },
  { OK: "Mayes" },
  { OK: "McClain" },
  { OK: "McCurtain" },
  { OK: "McIntosh" },
  { OK: "Murray" },
  { OK: "Muskogee" },
  { OK: "Noble" },
  { OK: "Nowata" },
  { OK: "Okfuskee" },
  { OK: "Oklahoma" },
  { OK: "Okmulgee" },
  { OK: "Osage" },
  { OK: "Ottawa" },
  { OK: "Pawnee" },
  { OK: "Payne" },
  { OK: "Pittsburg" },
  { OK: "Pontotoc" },
  { OK: "Pottawatomie" },
  { OK: "Pushmataha" },
  { OK: "Roger Mills" },
  { OK: "Rogers" },
  { OK: "Seminole" },
  { OK: "Sequoyah" },
  { OK: "Stephens" },
  { OK: "Texas" },
  { OK: "Tillman" },
  { OK: "Tulsa" },
  { OK: "Wagoner" },
  { OK: "Washington" },
  { OK: "Washita" },
  { OK: "Woods" },
  { OK: "Woodward" },
  { OR: "Baker" },
  { OR: "Benton" },
  { OR: "Clackamas" },
  { OR: "Clatsop" },
  { OR: "Columbia" },
  { OR: "Coos" },
  { OR: "Crook" },
  { OR: "Curry" },
  { OR: "Deschutes" },
  { OR: "Douglas" },
  { OR: "Gilliam" },
  { OR: "Grant" },
  { OR: "Harney" },
  { OR: "Hood River" },
  { OR: "Jackson" },
  { OR: "Jefferson" },
  { OR: "Josephine" },
  { OR: "Klamath" },
  { OR: "Lake" },
  { OR: "Lane" },
  { OR: "Lincoln" },
  { OR: "Linn" },
  { OR: "Malheur" },
  { OR: "Marion" },
  { OR: "Morrow" },
  { OR: "Multnomah" },
  { OR: "Polk" },
  { OR: "Sherman" },
  { OR: "Tillamook" },
  { OR: "Umatilla" },
  { OR: "Union" },
  { OR: "Wallowa" },
  { OR: "Wasco" },
  { OR: "Washington" },
  { OR: "Wheeler" },
  { OR: "Yamhill" },
  { PA: "Adams" },
  { PA: "Allegheny" },
  { PA: "Armstrong" },
  { PA: "Beaver" },
  { PA: "Bedford" },
  { PA: "Berks" },
  { PA: "Blair" },
  { PA: "Bradford" },
  { PA: "Bucks" },
  { PA: "Butler" },
  { PA: "Cambria" },
  { PA: "Cameron" },
  { PA: "Carbon" },
  { PA: "Centre" },
  { PA: "Chester" },
  { PA: "Clarion" },
  { PA: "Clearfield" },
  { PA: "Clinton" },
  { PA: "Columbia" },
  { PA: "Crawford" },
  { PA: "Cumberland" },
  { PA: "Dauphin" },
  { PA: "Delaware" },
  { PA: "Elk" },
  { PA: "Erie" },
  { PA: "Fayette" },
  { PA: "Forest" },
  { PA: "Franklin" },
  { PA: "Fulton" },
  { PA: "Greene" },
  { PA: "Huntingdon" },
  { PA: "Indiana" },
  { PA: "Jefferson" },
  { PA: "Juniata" },
  { PA: "Lackawanna" },
  { PA: "Lancaster" },
  { PA: "Lawrence" },
  { PA: "Lebanon" },
  { PA: "Lehigh" },
  { PA: "Luzerne" },
  { PA: "Lycoming" },
  { PA: "McKean" },
  { PA: "Mercer" },
  { PA: "Mifflin" },
  { PA: "Monroe" },
  { PA: "Montgomery" },
  { PA: "Montour" },
  { PA: "Northampton" },
  { PA: "Northumberland" },
  { PA: "Perry" },
  { PA: "Philadelphia" },
  { PA: "Pike" },
  { PA: "Potter" },
  { PA: "Schuylkill" },
  { PA: "Snyder" },
  { PA: "Somerset" },
  { PA: "Sullivan" },
  { PA: "Susquehanna" },
  { PA: "Tioga" },
  { PA: "Union" },
  { PA: "Venango" },
  { PA: "Warren" },
  { PA: "Washington" },
  { PA: "Wayne" },
  { PA: "Westmoreland" },
  { PA: "Wyoming" },
  { PA: "York" },
  { RI: "Bristol" },
  { RI: "Kent" },
  { RI: "Newport" },
  { RI: "Providence" },
  { RI: "Washington" },
  { SC: "Abbeville" },
  { SC: "Aiken" },
  { SC: "Allendale" },
  { SC: "Anderson" },
  { SC: "Bamberg" },
  { SC: "Barnwell" },
  { SC: "Beaufort" },
  { SC: "Berkeley" },
  { SC: "Calhoun" },
  { SC: "Charleston" },
  { SC: "Cherokee" },
  { SC: "Chester" },
  { SC: "Chesterfield" },
  { SC: "Clarendon" },
  { SC: "Colleton" },
  { SC: "Darlington" },
  { SC: "Dillon" },
  { SC: "Dorchester" },
  { SC: "Edgefield" },
  { SC: "Fairfield" },
  { SC: "Florence" },
  { SC: "Georgetown" },
  { SC: "Greenville" },
  { SC: "Greenwood" },
  { SC: "Hampton" },
  { SC: "Horry" },
  { SC: "Jasper" },
  { SC: "Kershaw" },
  { SC: "Lancaster" },
  { SC: "Laurens" },
  { SC: "Lee" },
  { SC: "Lexington" },
  { SC: "Marion" },
  { SC: "Marlboro" },
  { SC: "McCormick" },
  { SC: "Newberry" },
  { SC: "Oconee" },
  { SC: "Orangeburg" },
  { SC: "Pickens" },
  { SC: "Richland" },
  { SC: "Saluda" },
  { SC: "Spartanburg" },
  { SC: "Sumter" },
  { SC: "Union" },
  { SC: "Williamsburg" },
  { SC: "York" },
  { SD: "Aurora" },
  { SD: "Beadle" },
  { SD: "Bennett" },
  { SD: "Bon Homme" },
  { SD: "Brookings" },
  { SD: "Brown" },
  { SD: "Brule" },
  { SD: "Buffalo" },
  { SD: "Butte" },
  { SD: "Campbell" },
  { SD: "Charles Mix" },
  { SD: "Clark" },
  { SD: "Clay" },
  { SD: "Codington" },
  { SD: "Corson" },
  { SD: "Custer" },
  { SD: "Davison" },
  { SD: "Day" },
  { SD: "Deuel" },
  { SD: "Dewey" },
  { SD: "Douglas" },
  { SD: "Edmunds" },
  { SD: "Fall River" },
  { SD: "Faulk" },
  { SD: "Grant" },
  { SD: "Gregory" },
  { SD: "Haakon" },
  { SD: "Hamlin" },
  { SD: "Hand" },
  { SD: "Hanson" },
  { SD: "Harding" },
  { SD: "Hughes" },
  { SD: "Hutchinson" },
  { SD: "Hyde" },
  { SD: "Jackson" },
  { SD: "Jerauld" },
  { SD: "Jones" },
  { SD: "Kingsbury" },
  { SD: "Lake" },
  { SD: "Lawrence" },
  { SD: "Lincoln" },
  { SD: "Lyman" },
  { SD: "Marshall" },
  { SD: "McCook" },
  { SD: "McPherson" },
  { SD: "Meade" },
  { SD: "Mellette" },
  { SD: "Miner" },
  { SD: "Minnehaha" },
  { SD: "Moody" },
  { SD: "Pennington" },
  { SD: "Perkins" },
  { SD: "Potter" },
  { SD: "Roberts" },
  { SD: "Sanborn" },
  { SD: "Shannon" },
  { SD: "Spink" },
  { SD: "Stanley" },
  { SD: "Sully" },
  { SD: "Todd" },
  { SD: "Tripp" },
  { SD: "Turner" },
  { SD: "Union" },
  { SD: "Walworth" },
  { SD: "Yankton" },
  { SD: "Ziebach" },
  { TN: "Anderson" },
  { TN: "Bedford" },
  { TN: "Benton" },
  { TN: "Bledsoe" },
  { TN: "Blount" },
  { TN: "Bradley" },
  { TN: "Campbell" },
  { TN: "Cannon" },
  { TN: "Carroll" },
  { TN: "Carter" },
  { TN: "Cheatham" },
  { TN: "Chester" },
  { TN: "Claiborne" },
  { TN: "Clay" },
  { TN: "Cocke" },
  { TN: "Coffee" },
  { TN: "Crockett" },
  { TN: "Cumberland" },
  { TN: "Davidson" },
  { TN: "Decatur" },
  { TN: "DeKalb" },
  { TN: "Dickson" },
  { TN: "Dyer" },
  { TN: "Fayette" },
  { TN: "Fentress" },
  { TN: "Franklin" },
  { TN: "Gibson" },
  { TN: "Giles" },
  { TN: "Grainger" },
  { TN: "Greene" },
  { TN: "Grundy" },
  { TN: "Hamblen" },
  { TN: "Hamilton" },
  { TN: "Hancock" },
  { TN: "Hardeman" },
  { TN: "Hardin" },
  { TN: "Hawkins" },
  { TN: "Haywood" },
  { TN: "Henderson" },
  { TN: "Henry" },
  { TN: "Hickman" },
  { TN: "Houston" },
  { TN: "Humphreys" },
  { TN: "Jackson" },
  { TN: "Jefferson" },
  { TN: "Johnson" },
  { TN: "Knox" },
  { TN: "Lake" },
  { TN: "Lauderdale" },
  { TN: "Lawrence" },
  { TN: "Lewis" },
  { TN: "Lincoln" },
  { TN: "Loudon" },
  { TN: "Macon" },
  { TN: "Madison" },
  { TN: "Marion" },
  { TN: "Marshall" },
  { TN: "Maury" },
  { TN: "McMinn" },
  { TN: "McNairy" },
  { TN: "Meigs" },
  { TN: "Monroe" },
  { TN: "Montgomery" },
  { TN: "Moore" },
  { TN: "Morgan" },
  { TN: "Obion" },
  { TN: "Overton" },
  { TN: "Perry" },
  { TN: "Pickett" },
  { TN: "Polk" },
  { TN: "Putnam" },
  { TN: "Rhea" },
  { TN: "Roane" },
  { TN: "Robertson" },
  { TN: "Rutherford" },
  { TN: "Scott" },
  { TN: "Sequatchie" },
  { TN: "Sevier" },
  { TN: "Shelby" },
  { TN: "Smith" },
  { TN: "Stewart" },
  { TN: "Sullivan" },
  { TN: "Sumner" },
  { TN: "Tipton" },
  { TN: "Trousdale" },
  { TN: "Unicoi" },
  { TN: "Union" },
  { TN: "Van Buren" },
  { TN: "Warren" },
  { TN: "Washington" },
  { TN: "Wayne" },
  { TN: "Weakley" },
  { TN: "White" },
  { TN: "Williamson" },
  { TN: "Wilson" },
  { TX: "Anderson" },
  { TX: "Andrews" },
  { TX: "Angelina" },
  { TX: "Aransas" },
  { TX: "Archer" },
  { TX: "Armstrong" },
  { TX: "Atascosa" },
  { TX: "Austin" },
  { TX: "Bailey" },
  { TX: "Bandera" },
  { TX: "Bastrop" },
  { TX: "Baylor" },
  { TX: "Bee" },
  { TX: "Bell" },
  { TX: "Bexar" },
  { TX: "Blanco" },
  { TX: "Borden" },
  { TX: "Bosque" },
  { TX: "Bowie" },
  { TX: "Brazoria" },
  { TX: "Brazos" },
  { TX: "Brewster" },
  { TX: "Briscoe" },
  { TX: "Brooks" },
  { TX: "Brown" },
  { TX: "Burleson" },
  { TX: "Burnet" },
  { TX: "Caldwell" },
  { TX: "Calhoun" },
  { TX: "Callahan" },
  { TX: "Cameron" },
  { TX: "Camp" },
  { TX: "Carson" },
  { TX: "Cass" },
  { TX: "Castro" },
  { TX: "Chambers" },
  { TX: "Cherokee" },
  { TX: "Childress" },
  { TX: "Clay" },
  { TX: "Cochran" },
  { TX: "Coke" },
  { TX: "Coleman" },
  { TX: "Collin" },
  { TX: "Collingsworth" },
  { TX: "Colorado" },
  { TX: "Comal" },
  { TX: "Comanche" },
  { TX: "Concho" },
  { TX: "Cooke" },
  { TX: "Coryell" },
  { TX: "Cottle" },
  { TX: "Crane" },
  { TX: "Crockett" },
  { TX: "Crosby" },
  { TX: "Culberson" },
  { TX: "Dallam" },
  { TX: "Dallas" },
  { TX: "Dawson" },
  { TX: "Deaf Smith" },
  { TX: "Delta" },
  { TX: "Denton" },
  { TX: "DeWitt" },
  { TX: "Dickens" },
  { TX: "Dimmit" },
  { TX: "Donley" },
  { TX: "Duval" },
  { TX: "Eastland" },
  { TX: "Ector" },
  { TX: "Edwards" },
  { TX: "El Paso" },
  { TX: "Ellis" },
  { TX: "Erath" },
  { TX: "Falls" },
  { TX: "Fannin" },
  { TX: "Fayette" },
  { TX: "Fisher" },
  { TX: "Floyd" },
  { TX: "Foard" },
  { TX: "Fort Bend" },
  { TX: "Franklin" },
  { TX: "Freestone" },
  { TX: "Frio" },
  { TX: "Gaines" },
  { TX: "Galveston" },
  { TX: "Garza" },
  { TX: "Gillespie" },
  { TX: "Glasscock" },
  { TX: "Goliad" },
  { TX: "Gonzales" },
  { TX: "Gray" },
  { TX: "Grayson" },
  { TX: "Gregg" },
  { TX: "Grimes" },
  { TX: "Guadalupe" },
  { TX: "Hale" },
  { TX: "Hall" },
  { TX: "Hamilton" },
  { TX: "Hansford" },
  { TX: "Hardeman" },
  { TX: "Hardin" },
  { TX: "Harris" },
  { TX: "Harrison" },
  { TX: "Hartley" },
  { TX: "Haskell" },
  { TX: "Hays" },
  { TX: "Hemphill" },
  { TX: "Henderson" },
  { TX: "Hidalgo" },
  { TX: "Hill" },
  { TX: "Hockley" },
  { TX: "Hood" },
  { TX: "Hopkins" },
  { TX: "Houston" },
  { TX: "Howard" },
  { TX: "Hudspeth" },
  { TX: "Hunt" },
  { TX: "Hutchinson" },
  { TX: "Irion" },
  { TX: "Jack" },
  { TX: "Jackson" },
  { TX: "Jasper" },
  { TX: "Jeff Davis" },
  { TX: "Jefferson" },
  { TX: "Jim Hogg" },
  { TX: "Jim Wells" },
  { TX: "Johnson" },
  { TX: "Jones" },
  { TX: "Karnes" },
  { TX: "Kaufman" },
  { TX: "Kendall" },
  { TX: "Kenedy" },
  { TX: "Kent" },
  { TX: "Kerr" },
  { TX: "Kimble" },
  { TX: "King" },
  { TX: "Kinney" },
  { TX: "Kleberg" },
  { TX: "Knox" },
  { TX: "La Salle" },
  { TX: "Lamar" },
  { TX: "Lamb" },
  { TX: "Lampasas" },
  { TX: "Lavaca" },
  { TX: "Lee" },
  { TX: "Leon" },
  { TX: "Liberty" },
  { TX: "Limestone" },
  { TX: "Lipscomb" },
  { TX: "Live Oak" },
  { TX: "Llano" },
  { TX: "Loving" },
  { TX: "Lubbock" },
  { TX: "Lynn" },
  { TX: "Madison" },
  { TX: "Marion" },
  { TX: "Martin" },
  { TX: "Mason" },
  { TX: "Matagorda" },
  { TX: "Maverick" },
  { TX: "McCulloch" },
  { TX: "McLennan" },
  { TX: "McMullen" },
  { TX: "Medina" },
  { TX: "Menard" },
  { TX: "Midland" },
  { TX: "Milam" },
  { TX: "Mills" },
  { TX: "Mitchell" },
  { TX: "Montague" },
  { TX: "Montgomery" },
  { TX: "Moore" },
  { TX: "Morris" },
  { TX: "Motley" },
  { TX: "Nacogdoches" },
  { TX: "Navarro" },
  { TX: "Newton" },
  { TX: "Nolan" },
  { TX: "Nueces" },
  { TX: "Ochiltree" },
  { TX: "Oldham" },
  { TX: "Orange" },
  { TX: "Palo Pinto" },
  { TX: "Panola" },
  { TX: "Parker" },
  { TX: "Parmer" },
  { TX: "Pecos" },
  { TX: "Polk" },
  { TX: "Potter" },
  { TX: "Presidio" },
  { TX: "Rains" },
  { TX: "Randall" },
  { TX: "Reagan" },
  { TX: "Real" },
  { TX: "Red River" },
  { TX: "Reeves" },
  { TX: "Refugio" },
  { TX: "Roberts" },
  { TX: "Robertson" },
  { TX: "Rockwall" },
  { TX: "Runnels" },
  { TX: "Rusk" },
  { TX: "Sabine" },
  { TX: "San Augustine" },
  { TX: "San Jacinto" },
  { TX: "San Patricio" },
  { TX: "San Saba" },
  { TX: "Schleicher" },
  { TX: "Scurry" },
  { TX: "Shackelford" },
  { TX: "Shelby" },
  { TX: "Sherman" },
  { TX: "Smith" },
  { TX: "Somervell" },
  { TX: "Starr" },
  { TX: "Stephens" },
  { TX: "Sterling" },
  { TX: "Stonewall" },
  { TX: "Sutton" },
  { TX: "Swisher" },
  { TX: "Tarrant" },
  { TX: "Taylor" },
  { TX: "Terrell" },
  { TX: "Terry" },
  { TX: "Throckmorton" },
  { TX: "Titus" },
  { TX: "Tom Green" },
  { TX: "Travis" },
  { TX: "Trinity" },
  { TX: "Tyler" },
  { TX: "Upshur" },
  { TX: "Upton" },
  { TX: "Uvalde" },
  { TX: "Val Verde" },
  { TX: "Van Zandt" },
  { TX: "Victoria" },
  { TX: "Walker" },
  { TX: "Waller" },
  { TX: "Ward" },
  { TX: "Washington" },
  { TX: "Webb" },
  { TX: "Wharton" },
  { TX: "Wheeler" },
  { TX: "Wichita" },
  { TX: "Wilbarger" },
  { TX: "Willacy" },
  { TX: "Williamson" },
  { TX: "Wilson" },
  { TX: "Winkler" },
  { TX: "Wise" },
  { TX: "Wood" },
  { TX: "Yoakum" },
  { TX: "Young" },
  { TX: "Zapata" },
  { TX: "Zavala" },
  { UT: "Beaver" },
  { UT: "Box Elder" },
  { UT: "Cache" },
  { UT: "Carbon" },
  { UT: "Daggett" },
  { UT: "Davis" },
  { UT: "Duchesne" },
  { UT: "Emery" },
  { UT: "Garfield" },
  { UT: "Grand" },
  { UT: "Iron" },
  { UT: "Juab" },
  { UT: "Kane" },
  { UT: "Millard" },
  { UT: "Morgan" },
  { UT: "Piute" },
  { UT: "Rich" },
  { UT: "Salt Lake" },
  { UT: "San Juan" },
  { UT: "Sanpete" },
  { UT: "Sevier" },
  { UT: "Summit" },
  { UT: "Tooele" },
  { UT: "Uintah" },
  { UT: "Utah" },
  { UT: "Wasatch" },
  { UT: "Washington" },
  { UT: "Wayne" },
  { UT: "Weber" },
  { VA: "Accomack" },
  { VA: "Albemarle" },
  { VA: "Alexandria" },
  { VA: "Alleghany" },
  { VA: "Amelia" },
  { VA: "Amherst" },
  { VA: "Appomattox" },
  { VA: "Arlington" },
  { VA: "Augusta" },
  { VA: "Bath" },
  { VA: "Bedford Co " },
  { VA: "Bland" },
  { VA: "Botetourt" },
  { VA: "Bristol" },
  { VA: "Brunswick" },
  { VA: "Buchanan" },
  { VA: "Buckingham" },
  { VA: "Buena Vista" },
  { VA: "Campbell" },
  { VA: "Caroline" },
  { VA: "Carroll" },
  { VA: "Charles City" },
  { VA: "Charlotte" },
  { VA: "Charlottesville" },
  { VA: "Chesapeake" },
  { VA: "Chesterfield" },
  { VA: "Clarke" },
  { VA: "Colonial Heights" },
  { VA: "Covington" },
  { VA: "Craig" },
  { VA: "Culpeper" },
  { VA: "Cumberland" },
  { VA: "Danville" },
  { VA: "Dickenson" },
  { VA: "Dinwiddie" },
  { VA: "Emporia" },
  { VA: "Essex" },
  { VA: "Fairfax" },
  { VA: "Fairfax Co " },
  { VA: "Falls Church" },
  { VA: "Fauquier" },
  { VA: "Floyd" },
  { VA: "Fluvanna" },
  { VA: "Franklin" },
  { VA: "Franklin Co " },
  { VA: "Frederick" },
  { VA: "Fredericksburg" },
  { VA: "Galax" },
  { VA: "Giles" },
  { VA: "Gloucester" },
  { VA: "Goochland" },
  { VA: "Grayson" },
  { VA: "Greene" },
  { VA: "Greensville" },
  { VA: "Halifax" },
  { VA: "Hampton" },
  { VA: "Hanover" },
  { VA: "Harrisonburg" },
  { VA: "Henrico" },
  { VA: "Henry" },
  { VA: "Highland" },
  { VA: "Hopewell" },
  { VA: "Isle of Wight" },
  { VA: "James City" },
  { VA: "King and Queen" },
  { VA: "King George" },
  { VA: "King William" },
  { VA: "Lancaster" },
  { VA: "Lee" },
  { VA: "Lexington" },
  { VA: "Loudoun" },
  { VA: "Louisa" },
  { VA: "Lunenburg" },
  { VA: "Lynchburg" },
  { VA: "Madison" },
  { VA: "Manassas" },
  { VA: "Manassas Park" },
  { VA: "Martinsville" },
  { VA: "Mathews" },
  { VA: "Mecklenburg" },
  { VA: "Middlesex" },
  { VA: "Montgomery" },
  { VA: "Nelson" },
  { VA: "New Kent" },
  { VA: "Newport News" },
  { VA: "Norfolk" },
  { VA: "Northampton" },
  { VA: "Northumberland" },
  { VA: "Norton" },
  { VA: "Nottoway" },
  { VA: "Orange" },
  { VA: "Page" },
  { VA: "Patrick" },
  { VA: "Petersburg" },
  { VA: "Pittsylvania" },
  { VA: "Poquoson" },
  { VA: "Portsmouth" },
  { VA: "Powhatan" },
  { VA: "Prince Edward" },
  { VA: "Prince George" },
  { VA: "Prince William" },
  { VA: "Pulaski" },
  { VA: "Radford" },
  { VA: "Rappahannock" },
  { VA: "Richmond" },
  { VA: "Richmond Co " },
  { VA: "Roanoke" },
  { VA: "Roanoke Co " },
  { VA: "Rockbridge" },
  { VA: "Rockingham" },
  { VA: "Russell" },
  { VA: "Salem" },
  { VA: "Scott" },
  { VA: "Shenandoah" },
  { VA: "Smyth" },
  { VA: "Southampton" },
  { VA: "Spotsylvania" },
  { VA: "Stafford" },
  { VA: "Staunton" },
  { VA: "Suffolk" },
  { VA: "Surry" },
  { VA: "Sussex" },
  { VA: "Tazewell" },
  { VA: "Virginia Beach" },
  { VA: "Warren" },
  { VA: "Washington" },
  { VA: "Waynesboro" },
  { VA: "Westmoreland" },
  { VA: "Williamsburg" },
  { VA: "Winchester" },
  { VA: "Wise" },
  { VA: "Wythe" },
  { VA: "York" },
  { VT: "Addison" },
  { VT: "Bennington" },
  { VT: "Caledonia" },
  { VT: "Chittenden" },
  { VT: "Essex" },
  { VT: "Franklin" },
  { VT: "Grand Isle" },
  { VT: "Lamoille" },
  { VT: "Orange" },
  { VT: "Orleans" },
  { VT: "Rutland" },
  { VT: "Washington" },
  { VT: "Windham" },
  { VT: "Windsor" },
  { WA: "Adams" },
  { WA: "Asotin" },
  { WA: "Benton" },
  { WA: "Chelan" },
  { WA: "Clallam" },
  { WA: "Clark" },
  { WA: "Columbia" },
  { WA: "Cowlitz" },
  { WA: "Douglas" },
  { WA: "Ferry" },
  { WA: "Franklin" },
  { WA: "Garfield" },
  { WA: "Grant" },
  { WA: "Grays Harbor" },
  { WA: "Island" },
  { WA: "Jefferson" },
  { WA: "King" },
  { WA: "Kitsap" },
  { WA: "Kittitas" },
  { WA: "Klickitat" },
  { WA: "Lewis" },
  { WA: "Lincoln" },
  { WA: "Mason" },
  { WA: "Okanogan" },
  { WA: "Pacific" },
  { WA: "Pend Oreille" },
  { WA: "Pierce" },
  { WA: "San Juan" },
  { WA: "Skagit" },
  { WA: "Skamania" },
  { WA: "Snohomish" },
  { WA: "Spokane" },
  { WA: "Stevens" },
  { WA: "Thurston" },
  { WA: "Wahkiakum" },
  { WA: "Walla Walla" },
  { WA: "Whatcom" },
  { WA: "Whitman" },
  { WA: "Yakima" },
  { WI: "Adams" },
  { WI: "Ashland" },
  { WI: "Barron" },
  { WI: "Bayfield" },
  { WI: "Brown" },
  { WI: "Buffalo" },
  { WI: "Burnett" },
  { WI: "Calumet" },
  { WI: "Chippewa" },
  { WI: "Clark" },
  { WI: "Columbia" },
  { WI: "Crawford" },
  { WI: "Dane" },
  { WI: "Dodge" },
  { WI: "Door" },
  { WI: "Douglas" },
  { WI: "Dunn" },
  { WI: "Eau Claire" },
  { WI: "Florence" },
  { WI: "Fond du Lac" },
  { WI: "Forest" },
  { WI: "Grant" },
  { WI: "Green" },
  { WI: "Green Lake" },
  { WI: "Iowa" },
  { WI: "Iron" },
  { WI: "Jackson" },
  { WI: "Jefferson" },
  { WI: "Juneau" },
  { WI: "Kenosha" },
  { WI: "Kewaunee" },
  { WI: "La Crosse" },
  { WI: "Lafayette" },
  { WI: "Langlade" },
  { WI: "Lincoln" },
  { WI: "Manitowoc" },
  { WI: "Marathon" },
  { WI: "Marinette" },
  { WI: "Marquette" },
  { WI: "Menominee" },
  { WI: "Milwaukee" },
  { WI: "Monroe" },
  { WI: "Oconto" },
  { WI: "Oneida" },
  { WI: "Outagamie" },
  { WI: "Ozaukee" },
  { WI: "Pepin" },
  { WI: "Pierce" },
  { WI: "Polk" },
  { WI: "Portage" },
  { WI: "Price" },
  { WI: "Racine" },
  { WI: "Richland" },
  { WI: "Rock" },
  { WI: "Rusk" },
  { WI: "Sauk" },
  { WI: "Sawyer" },
  { WI: "Shawano" },
  { WI: "Sheboygan" },
  { WI: "St  Croix" },
  { WI: "Taylor" },
  { WI: "Trempealeau" },
  { WI: "Vernon" },
  { WI: "Vilas" },
  { WI: "Walworth" },
  { WI: "Washburn" },
  { WI: "Washington" },
  { WI: "Waukesha" },
  { WI: "Waupaca" },
  { WI: "Waushara" },
  { WI: "Winnebago" },
  { WI: "Wood" },
  { WV: "Barbour" },
  { WV: "Berkeley" },
  { WV: "Boone" },
  { WV: "Braxton" },
  { WV: "Brooke" },
  { WV: "Cabell" },
  { WV: "Calhoun" },
  { WV: "Clay" },
  { WV: "Doddridge" },
  { WV: "Fayette" },
  { WV: "Gilmer" },
  { WV: "Grant" },
  { WV: "Greenbrier" },
  { WV: "Hampshire" },
  { WV: "Hancock" },
  { WV: "Hardy" },
  { WV: "Harrison" },
  { WV: "Jackson" },
  { WV: "Jefferson" },
  { WV: "Kanawha" },
  { WV: "Lewis" },
  { WV: "Lincoln" },
  { WV: "Logan" },
  { WV: "Marion" },
  { WV: "Marshall" },
  { WV: "Mason" },
  { WV: "McDowell" },
  { WV: "Mercer" },
  { WV: "Mineral" },
  { WV: "Mingo" },
  { WV: "Monongalia" },
  { WV: "Monroe" },
  { WV: "Morgan" },
  { WV: "Nicholas" },
  { WV: "Ohio" },
  { WV: "Pendleton" },
  { WV: "Pleasants" },
  { WV: "Pocahontas" },
  { WV: "Preston" },
  { WV: "Putnam" },
  { WV: "Raleigh" },
  { WV: "Randolph" },
  { WV: "Ritchie" },
  { WV: "Roane" },
  { WV: "Summers" },
  { WV: "Taylor" },
  { WV: "Tucker" },
  { WV: "Tyler" },
  { WV: "Upshur" },
  { WV: "Wayne" },
  { WV: "Webster" },
  { WV: "Wetzel" },
  { WV: "Wirt" },
  { WV: "Wood" },
  { WV: "Wyoming" },
  { WY: "Albany" },
  { WY: "Big Horn" },
  { WY: "Campbell" },
  { WY: "Carbon" },
  { WY: "Converse" },
  { WY: "Crook" },
  { WY: "Fremont" },
  { WY: "Goshen" },
  { WY: "Hot Springs" },
  { WY: "Johnson" },
  { WY: "Laramie" },
  { WY: "Lincoln" },
  { WY: "Natrona" },
  { WY: "Niobrara" },
  { WY: "Park" },
  { WY: "Platte" },
  { WY: "Sheridan" },
  { WY: "Sublette" },
  { WY: "Sweetwater" },
  { WY: "Teton" },
  { WY: "Uinta" },
  { WY: "Washakie" },
  { WY: "Weston" },
];

const refinedCounties = () => {
  let counties = {};
  for (let i of countyDict) {
    let key = Object.keys(i)[0];
    if (counties[key] === undefined) {
      counties[key] = [i[key]];
    } else {
      let myList = counties[key];
      myList.push(i[key]);
      counties[key] = myList;
    }
  }
  return counties;
};

const d = refinedCounties();

export default d;
