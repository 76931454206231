import React from "react";

const RadioButton = ({ value, name, id, hiddenvalue }) => {
  return (
    <label className="radio-button">
      <input
        id={id}
        name={name}
        type="radio"
        hiddenvalue={hiddenvalue}
        required
      />
      {value}
    </label>
  );
};

export default RadioButton;
