import React from "react";
import "./CSS/footer.css";

const FooterComponent = () => {
  const footerLogo = require("../src/Basf-Logo.jpg");
  const footerQR = require("../src/qrcode.png");
  return (
    <footer className="footer">
      <div className="footer-qrcode">
        <div className="image">
          <img alt="QR code" src={footerQR} />
        </div>
        <div className="qr-message">
          <p className="text-blue">Can't stick around?</p>
          <p>Scan to access these <nobr>on-the-go.</nobr></p>
        </div>
      </div>
      <a className="footer-logo" href="https://www.basf.com/us/en.html" target="_blank" rel="noopener noreferrer">
        <div>
          <img alt="BASF logo" src={footerLogo} />
        </div>
      </a>
    </footer>
  );
};

export default FooterComponent;
