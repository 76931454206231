import React from "react";
import "./CSS/givingThanksForm.css";
import { useState, useMemo } from "react";
import stateLabelValues from "./stateValues";
import { useNavigate } from "react-router-dom";
import { useForm } from 'react-hook-form'; // https://react-hook-form.com/get-started/

const GivingThanksForm = () => {
  const hero = require("../src/contact-form-hero.jpg");

  const navigate = useNavigate();

  const { register, handleSubmit, watch, formState: { errors } } = useForm();
  const watchCheckboxCorn = watch('corn', false);
  const watchInputCornAcres = watch('corn-acres', '');
  const watchCheckboxSoy = watch('soy', false);
  const watchInputSoyAcres = watch('soy-acres', '');
  const watchCheckboxWheat = watch('wheat', false);
  const watchInputWheatAcres = watch('wheat-acres', '');
  const watchCheckboxCotton = watch('cotton', false);
  const watchInputCottonAcres = watch('cotton-acres', '');
  const watchTextareaCrops = watch('Crops_Text_Box', '');

  const [isCropsFieldsetTouched, setIsCropsFieldsetTouched] = useState(false);
  const [isSubmitButtonPressed, setIsSubmitButtonPressed] = useState(false);

  const isCropsFieldsetValid = useMemo(() => {
    const isAtLeastOneCheckboxChecked = (watchCheckboxCorn || watchCheckboxSoy || watchCheckboxWheat || watchCheckboxCotton);
    const doesEachCheckedBoxHaveAValue = (
      (!watchCheckboxCorn || (watchCheckboxCorn && watchInputCornAcres !== '')) &&
      (!watchCheckboxSoy || (watchCheckboxSoy && watchInputSoyAcres !== '')) &&
      (!watchCheckboxWheat || (watchCheckboxWheat && watchInputWheatAcres !== '')) &&
      (!watchCheckboxCotton || (watchCheckboxCotton && watchInputCottonAcres !== ''))
    );
    const isTextareaFilled = watchTextareaCrops !== '';

    const valid = (isAtLeastOneCheckboxChecked || isTextareaFilled) && doesEachCheckedBoxHaveAValue;
    return valid;
  }, [watchCheckboxCorn, watchInputCornAcres, watchCheckboxSoy, watchInputSoyAcres, 
    watchCheckboxWheat, watchInputWheatAcres, watchCheckboxCotton, watchInputCottonAcres,
    watchTextareaCrops]);

  const encode = (data) => {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
      )
      .join("&");

  };

  const onFormSubmit = (data) => {
    console.log('form submitted', data);
    // remove any previously entered data if the associated crop checkbox
    // was unchecked
    if (!watchCheckboxCorn) {
      delete data["corn-acres"];
    }
    if (!watchCheckboxSoy) {
      delete data["soy-acres"];
    }
    if (!watchCheckboxWheat) {
      delete data["wheat-acres"];
    }
    if (!watchCheckboxCotton) {
      delete data["cotton-acres"];
    }

    const isFormValid = Object.keys(errors).length === 0 & isCropsFieldsetValid;
    // console.log('form valid', isFormValid);

    if (isFormValid) {
      // console.log('submitting form', data);
      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode(data),
      })
        .then(() => navigate("/giving-thanks-form-confirmation/"))
        .catch((error) => console.error(error));
    }
  }

  const onFormError = (data) => {
    console.log('form has errors', data, 'crops field', isCropsFieldsetValid);
    setIsCropsFieldsetTouched(true);
    setIsSubmitButtonPressed(true);
  }

  return (
    <div className="contact-form">
      <div className="hero">
        <img src={hero} />
      </div>

      <div className="content">
        <h3>
          We’re giving away an iPad! Fill out this form for a chance to win.
        </h3>
        <p>*Required fields.</p>

        <form
          name="giving-thanks"
          data-netlify="true"
          onSubmit={handleSubmit(onFormSubmit, onFormError)}
        >
          <input type="hidden"
            {...register("form-name", { required: true, value: "giving-thanks" })}
          />

          <div className="question">
            <div className="text-input-group">
              <div className="form-field first-name">
                <input
                  id="firstName"
                  type="text"
                  placeholder="FIRST NAME*"
                  {...register("first", { required: true })}

                ></input>

                <span
                  className={
                    errors.first
                      ? "validation-error"
                      : "no-validation-error"
                  }
                >
                  *Please enter a valid first name
                </span>
              </div>

              <div className="form-field last-name">
                <input
                  type="text"
                  id="lastName"
                  placeholder="LAST NAME*"
                  {...register("last", { required: true })}
                ></input>

                <span
                  className={
                    errors.last
                      ? "validation-error"
                      : "no-validation-error"
                  }
                >
                  *Please enter a valid last name
                </span>
              </div>

              <div className="form-field farm-name">
                <input
                  type="text"
                  placeholder="FARM NAME (If Applicable)"
                  {...register("farm-name")}
                ></input>
              </div>

              <div className="form-field address">
                <input
                  type="text"
                  id="address"
                  placeholder="MAILING ADDRESS (NO P.O. BOXES)*"
                  {...register("address", { required: true })}
                ></input>

                <span
                  className={
                    errors.address
                      ? "validation-error"
                      : "no-validation-error"
                  }
                >
                  *Please enter a valid mailing address
                </span>
              </div>

              <div className="form-field city">
                <input
                  id="city"
                  type="text"
                  name="city"
                  placeholder="CITY*"
                  {...register("city", { required: true })}
                ></input>

                <span
                  className={
                    errors.city
                      ? "validation-error"
                      : "no-validation-error"
                  }
                >
                  *Please enter a valid city
                </span>
              </div>

              <div className="form-field select state">
                <div className="select-wrapper">
                  <select
                    id="state-dropdown"
                    name="state"
                    {...register("state", { required: true })}
                  >
                    <option value="">STATE*</option>
                    {stateLabelValues.map((state) => {
                      return <option value={state.value}>{state.label}</option>;
                    })}
                  </select>
                </div>
                <span
                  className={
                    errors.state
                      ? "validation-error"
                      : "no-validation-error"
                  }
                >
                  *Please select a state
                </span>
              </div>

              <div className="form-field zipcode">
                <input
                  type="text"
                  name="zipcode"
                  placeholder="ZIP CODE*"
                  {...register("zipcode", {
                    required: true,
                    pattern: /(^\d{5}$)|(^\d{5}-\d{4}$)/i
                  })}
                ></input>
                <span
                  className={
                    errors.zipcode
                      ? "validation-error"
                      : "no-validation-error"
                  }
                >
                  *Please enter a valid US zip code
                </span>
              </div>

              <div className="form-field number phone-number">
                <input
                  type="text"
                  placeholder="BUSINESS PHONE NUMBER*"
                  {...register("phone_number", {
                    required: true,
                    pattern: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/gim
                  })}
                ></input>
                <span
                  className={
                    errors.phone_number
                      ? "validation-error"
                      : "no-validation-error"
                  }
                >
                  *Please enter a valid phone number
                </span>
              </div>

              <div className="form-field email">
                <input
                  type="text"
                  name="email"
                  placeholder="BUSINESS EMAIL ADDRESS*"
                  {...register("email", {
                    required: true,
                    pattern: /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
                  })}
                ></input>
                <span
                  className={
                    errors.email
                      ? "validation-error"
                      : "no-validation-error"
                  }
                >
                  *Please enter a valid email
                </span>
              </div>
            </div>
          </div>

          <div className="question">
            <label>PREFERRED METHOD OF CONTACT*</label>
            <div className="select-wrapper">
              <select
                {...register('preferred-method', { required: true })}
              >
                <option>EMAIL</option>
                <option>PHONE NUMBER</option>
              </select>
            </div>
          </div>

          <div className="question">
            <label>WOULD YOU LIKE TO BE NOTIFIED ABOUT UPCOMING INNOVATIONS?*</label>
            <div className="select-wrapper">
              <select {...register("opt-in-for-notifications", { required: true })}>
                <option>YES</option>
                <option>NO</option>
              </select>
            </div>
          </div>

          <div className="question">
            <label>
              WHICH CROP(S) DO YOU GROW?* (PLEASE INDICATE ACRES FOR EACH, CHECK ALL THAT APPLY)
            </label>
            <span
              className={
                !isSubmitButtonPressed || (isSubmitButtonPressed && isCropsFieldsetValid)
                  ? "no-validation-error"
                  : "validation-error"
              }
            >
              *Please select at least one checkbox and enter acres, and/or fill out the Other field
            </span>
            <div
              id="crops-checkbox"
              className="checkbox-group"
              onClick={() => setIsCropsFieldsetTouched(true)}>
              <div>
                <label className="checkbox">
                  <input type="checkbox" {...register("corn")} /> Corn
                </label>
                <label className={watchCheckboxCorn ? 'show' : 'hide'}>
                  <input type="text"
                    {...register("corn-acres", { validate: {positive: v => watchCheckboxCorn ? parseFloat(v) > 0 : true }})}
                  />
                  &nbsp;acres
                </label>
                <span className={(!isSubmitButtonPressed || watchInputCornAcres > 0) || !watchCheckboxCorn ? "no-validation-error" : "validation-error"}>Enter a numeric value greater than 0</span>
              </div>
              <div>
                <label className="checkbox">
                  <input type="checkbox" {...register("soy")} /> Soy
                </label>
                <label className={watchCheckboxSoy ? 'show' : 'hide'}>
                  <input type="text"
                    {...register("soy-acres", { validate: {positive: v => watchCheckboxSoy ? parseFloat(v) > 0 : true }})}
                  />
                  &nbsp;acres
                </label>
                <span className={(!isSubmitButtonPressed || watchInputSoyAcres > 0) || !watchCheckboxSoy ? "no-validation-error" : "validation-error"}>Enter a numeric value greater than 0</span>
              </div>
              <div>
                <label className="checkbox">
                  <input type="checkbox" {...register("wheat")} /> Wheat
                </label>
                <label className={watchCheckboxWheat ? 'show' : 'hide'}>
                  <input type="text"
                    {...register("wheat-acres", { validate: {positive: v => watchCheckboxWheat ? parseFloat(v) > 0 : true }})}
                  />
                  &nbsp;acres
                </label>
                <span className={(!isSubmitButtonPressed || watchInputWheatAcres > 0) || !watchCheckboxWheat ? "no-validation-error" : "validation-error"}>Enter a numeric value greater than 0</span>
              </div>
              <div>
                <label className="checkbox">
                  <input type="checkbox" {...register("cotton")} /> Cotton
                </label>
                <label className={watchCheckboxCotton ? 'show' : 'hide'}>
                  <input type="text"
                    {...register("cotton-acres", { validate: {positive: v => watchCheckboxCotton ? parseFloat(v) > 0 : true }})}
                  />
                  &nbsp;acres
                </label>
                <span className={(!isSubmitButtonPressed || watchInputCottonAcres > 0) || !watchCheckboxCotton ? "no-validation-error" : "validation-error"}>Enter a numeric value greater than 0</span>
              </div>
            </div>

            <label>Other (fill in the text box)</label>
            <textarea
              rows="4"
              cols="50"
              {...register("Crops_Text_Box")}
            ></textarea>
          </div>

          <div className="question">
            <p className="disclaimer">
              All information collected will be treated in accordance with our{" "}
              <a
                href="https://www.basf.com/us/en/legal/data-protection.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </a>
              . Please see our{" "}
              <a
                href="https://www.basf.com/us/en/legal/data-protection.html#text-1150390576"
                target="_blank"
                rel="noopener noreferrer"
              >
                CCPA Notice of Collection
              </a>{" "}
              as well.
            </p>
            <p className="disclaimer">By submitting this form, I agree to the <a
                href="/GivingThanks_TermsAndConditions.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >Terms and Conditions</a>.</p>
          </div>

          <input type="submit" value="SUBMIT" />
        </form>
      </div>
    </div>
  );
};

export default GivingThanksForm;
