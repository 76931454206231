import React from "react";
import headerLogo from "./home_svg.svg";
import "./CSS/header.css";
import { useNavigate } from "react-router-dom";

const HeaderComponent = () => {
  const navigate = useNavigate();
  return (
    <div className="header">
      <div className="border">
        <img src={headerLogo} height="78px" onClick={() => navigate("/")}></img>
      </div>
    </div>
  );
};

export default HeaderComponent;
